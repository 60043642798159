import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons'; // Import LoadingOutlined

import {
  ButtonWrapper,
  ContentDiv1,
  ContentDiv2,
  ContentDivBlock,
  ContentWrapper,
  FormWrapperDiv,
  InputField,
  InputFieldBlock,
  InputFieldDiv,
  LanguageSelectDiv,
  PresentationContainer,
  PurchaseButton,
  SubmitButton,
  TextField,
} from '../PresentationLetter/PresentationLetter.styles.js';
import { Radio, Form, Input, message, Spin, Modal } from 'antd';
import Select from 'react-select';
import countries from 'react-select-country-list';
import axios from 'axios';
import { Store } from '../../Store.js';
import { useNavigate } from 'react-router-dom';
import i18n from '../i18n.js';
const { Option } = Select;

const Invitation = () => {
  const { t } = useTranslation();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const currentLanguage = i18n.language; // get the current language

  const [formData, setFormData] = useState(() => {
    const storedData = JSON.parse(
      localStorage.getItem(`formData_${window.location.pathname}`)
    );
    return (
      storedData || {
        name: '',
        lastName: '',
        Partyreason: '',
        placeoftheparty: '',
        country: '',
        city: '',
        Partystarttime: '',
        Partyendtime: '',
        Specialinstructionsforguests: '',
        email: '',
        language: 'spanish',
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      `formData_${window.location.pathname}`,
      JSON.stringify(formData)
    );
  }, [formData]);
  const [loading, setLoading] = useState(false);
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!state.userInfo && !state.userInfo?.isActivated) {
      navigate(`/${currentLanguage}/Login`);
    } else {
      checkFormAndPaymentStatus();
    }
  }, [navigate, state.userInfo]);

  const checkFormAndPaymentStatus = async () => {
    try {
      const submittedFormResponse = await axios.get(
        `https://elescritor-io-22.onrender.com/api/users/${state.userInfo._id}/hasSubmittedForm`
      );
      setHasSubmittedForm(
        submittedFormResponse.data.hasSubmittedPartyInviteLetter
      );

      const paidResponse = await axios.get(
        `https://elescritor-io-22.onrender.com/api/orders/${state.userInfo._id}/isPaid/L02_Invitacion`,
        {
          headers: {
            Authorization: `Bearer ${state.userInfo.token}`,
          },
        }
      );
      setHasPaid(paidResponse.data.hasPaid);
    } catch (error) {
      console.error('Error checking form and payment status:', error);
      // Handle error...
    }
  };
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setFormData({ ...formData, language: selectedLanguage });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/invitationpartyform',
        {
          userId: state.userInfo._id,
          formData: formData,
          formType: 'PartyInvite',
          language: formData.language,
          formSubmitted: true, // Adding formSubmitted status
        }
      );

      if (response.status === 201) {
        message.success('Form submitted successfully.');
        setHasSubmittedForm(true);

        const currentLanguagePrefix = window.location.pathname.split('/')[1];
        const languagePrefix =
          currentLanguagePrefix === 'es' || currentLanguagePrefix === 'en'
            ? `/${currentLanguagePrefix}`
            : '';

        navigate(`${languagePrefix}/Thankyoumessage`);

        setFormData({
          name: '',
          lastName: '',
          Partyreason: '',
          placeoftheparty: '',
          country: '',
          city: '',
          Partystarttime: '',
          Partyendtime: '',
          Specialinstructionsforguests: '',
          email: '',
          language: 'spanish',
        });
      } else {
        message.error('Failed to submit form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error.response.data.message);
      message.error(error.response.data.message || 'Failed to submit form.');
    } finally {
      setLoading(false);
    }
  };
  const handlePurchase = (letterName, letterPrice) => {
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { letterName, letterPrice },
    });
    navigate(`/${currentLanguage}/SelectPaymentMethod`);
  };

  const countryOptions = countries().getData();
  return (
    <React.Fragment>
      <Form onFinish={handleSubmit}>
        <PresentationContainer className="PresentationContainerMob">
          <ContentWrapper className="ContentWrapperMob">
            <ContentDiv1 className="ContentDiv1Mob">
              <h1>
                {t('Form.title')} &nbsp;
                <span>
                  {t('Form.title2')}
                  <br />
                  {t('Form.title3')}
                </span>
                <p>{t('Form.description')}</p>
                <p>
                  <strong>{t('Form.Strong')}</strong> {t('Form.Note')}
                </p>
              </h1>
            </ContentDiv1>
            <ContentDiv2 className="ContentDiv2Mob">
              <h1>
                {t('Form.FreeHeading')}
                <span> {t('Form.Free')}</span>
              </h1>
              <ContentDivBlock>
                <h3> {t('Form.InvitationHeading')}</h3>
                <p>{t('Form.InvitationDescription')}</p>
              </ContentDivBlock>
            </ContentDiv2>
          </ContentWrapper>
          <FormWrapperDiv className="FormWrapperDivMob">
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.yourName')}{' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.yourLastName')}{' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {t('Invitation.Partyreason')} <br />
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  value={formData.Partyreason}
                  onChange={(e) =>
                    setFormData({ ...formData, Partyreason: e.target.value })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.Numberandstreet/placeoftheparty')}{' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.placeoftheparty}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      placeoftheparty: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.country')}{' '}
                </label>
                <Form.Item
                  name="country"
                  className="InputFieldMob"
                  rules={[
                    {
                      required: true,
                      message: t('presentationLetter.countryRequired.message'),
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginTop: '5px',
                    marginRight: '16px',
                  }}
                >
                  <Select
                    value={formData.country}
                    onChange={(selectedOption) =>
                      setFormData({
                        ...formData,
                        country: selectedOption.value,
                      })
                    }
                    options={countryOptions}
                    required
                    isSearchable
                    placeholder={t('presentationLetter.country')}
                  />
                </Form.Item>
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel"> {t('Invitation.city')} </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.Partystarttime')}{' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.Partystarttime}
                  onChange={(e) =>
                    setFormData({ ...formData, Partystarttime: e.target.value })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.Partyendtime')}{' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.Partyendtime}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Partyendtime: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('Invitation.Specialinstructionsforguests')}{' '}
                </label>
                <TextField
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.Specialinstructionsforguests}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Specialinstructionsforguests: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel"> {t('Invitation.email')} </label>
                <Form.Item
                  name="email"
                  className="InputFieldMob"
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginRight: '2%',
                    marginTop: '10px',
                  }}
                  rules={[
                    {
                      required: true,
                      message: t('presentationLetter.emailRequired.message'),
                    },
                    {
                      type: 'email',
                      message: t('presentationLetter.emailValidation.message'),
                    },
                  ]}
                >
                  <Input
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    style={{ height: '44px' }}
                    required
                    type="email"
                    placeholder={t('Invitation.email')}
                  />
                </Form.Item>{' '}
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <LanguageSelectDiv className="LanguageSelectDivMob">
                  <p>{t('Invitation.languageNote')}</p>
                  <div className="radioblocks">
                    <Radio.Group
                      value={formData.language}
                      onChange={handleLanguageChange}
                      name="language"
                    >
                      <Radio value="spanish">{t('Invitation.spanish')} </Radio>
                      <Radio value="english"> {t('Invitation.english')} </Radio>
                    </Radio.Group>
                  </div>
                </LanguageSelectDiv>
              </InputFieldBlock>
            </InputFieldDiv>
            <ButtonWrapper>
              <h1>{t('presentationLetter.generatingLetterMessage')}</h1>

              <SubmitButton
                type="submit"
                className="submitbtn"
                disabled={hasSubmittedForm}
                style={{
                  backgroundColor: hasSubmittedForm ? '#e0e0e0' : '',
                }}
                onClick={(e) => {
                  if (hasSubmittedForm) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  t('presentationLetter.generateLetterButton')
                )}
              </SubmitButton>

              <PurchaseButton
                onClick={(e) => {
                  if (!hasSubmittedForm) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    handlePurchase(
                      t('L02_Invitacion'),
                      hasSubmittedForm ? 2.99 : 2.99
                    );
                  }
                }}
                className="purchasebtn"
                disabled={!hasSubmittedForm}
                style={{
                  backgroundColor: !hasSubmittedForm ? '#e0e0e0' : '',
                }}
              >
                {t('LoveLetter.generateLetterButton')}
              </PurchaseButton>
            </ButtonWrapper>
          </FormWrapperDiv>
        </PresentationContainer>
      </Form>
    </React.Fragment>
  );
};

export default Invitation;
