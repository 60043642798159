import React from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const NotFound = () => {
  const { t } = useTranslation();

  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center "> {t('error404.title')}</h1>
              </div>
              <div className="contant_box_404">
                <h3 className="h2"> {t('error404.text')}</h3>
                <p> {t('error404.para')}</p>
                <Link to="/" className="link_404">
                  {t('error404.button')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
