import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import {
  Block1,
  Block2,
  Block3,
  FreeLetterWrapper,
  LetterBlockDiv,
  LetterContainer,
  LetterHeading,
  LetterSpan,
  MainHeading,
  TitleBlock,
  TopPara,
} from './FreeLetters.styles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FreeSignupButton, SignupButton } from '../Signup/Signup.styles';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../../Store'; // Import Store context
import i18n from '../i18n';

const FreeLetters = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const currentLanguage = i18n.language; // get the current language

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  const handleClick = (letter) => {
    setSelectedLetter(letter);
    if (!userInfo) {
      setIsModalVisible(true);
    } else {
      navigate(`/${currentLanguage}/${letter}`); // Navigate to the letter page
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSignUp = () => {
    // Navigate to the sign-up page
    navigate(`/${currentLanguage}/Signup`); // Navigate to the letter page
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <LetterContainer className="letterContainerweb">
        <MainHeading className="mainheadingmob">
          {t('freeLetters.mainHeading')}
        </MainHeading>
        <TopPara className="toparamob">{t('freeLetters.subHeading')}</TopPara>
        <FreeLetterWrapper className="freeletterwrappermob">
          <TitleBlock>
            <LetterHeading>
              {t('freeLetters.firstLetterTitle')}{' '}
              <LetterSpan>{t('freeLetters.free')}</LetterSpan>
            </LetterHeading>
          </TitleBlock>
          <LetterBlockDiv>
            <Block1
              data-aos="fade-right"
              onClick={() => handleClick('L01Presentacion')}
            >
              <h4>{t('freeLetters.presentationLetter')}</h4>
              <p>{t('freeLetters.presentationLetterDescription')}</p>
            </Block1>
          </LetterBlockDiv>
          <LetterBlockDiv>
            <Block2 onClick={() => handleClick('L02_Invitacion')}>
              <h4>{t('freeLetters.partyInvitation')}</h4>
              <p>{t('freeLetters.partyInvitationDescription')}</p>
            </Block2>
          </LetterBlockDiv>
          <LetterBlockDiv>
            <Block3
              data-aos="fade-left"
              onClick={() => handleClick('L03_Reanudar')}
            >
              <h4>{t('freeLetters.resumeFriendship')}</h4>
              <p>{t('freeLetters.resumeFriendshipDescription')}</p>
            </Block3>
          </LetterBlockDiv>
        </FreeLetterWrapper>
        <Modal
          title={t('freelettersignupmodal.title')}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <p>{t('freelettersignupmodal.bodytext')}</p>
          <Link to={`/${currentLanguage}/Signup`}>
            <FreeSignupButton onClick={handleSignUp}>
              {t('freelettersignupmodal.signupbtn')}
            </FreeSignupButton>
          </Link>
        </Modal>
      </LetterContainer>
      <LetterContainer className="letterContainermob">
        <MainHeading className="mainheadingmob">
          {t('freeLetters.mainHeading')}
        </MainHeading>
        <TopPara className="toparamob">{t('freeLetters.subHeading')}</TopPara>
        <FreeLetterWrapper className="freeletterwrappermob">
          <TitleBlock>
            <LetterHeading>
              {t('freeLetters.firstLetterTitle')}{' '}
              <LetterSpan>{t('freeLetters.free')}</LetterSpan>
            </LetterHeading>
          </TitleBlock>
          <LetterBlockDiv>
            <Block1
              data-aos="fade-right"
              onClick={() => handleClick('L01Presentacion')}
            >
              <h4>{t('freeLetters.presentationLetter')}</h4>
              <p>{t('freeLetters.presentationLetterDescription')}</p>
            </Block1>
          </LetterBlockDiv>
          <LetterBlockDiv>
            <Block2 onClick={() => handleClick('L02_Invitacion')}>
              <h4>{t('freeLetters.partyInvitation')}</h4>
              <p>{t('freeLetters.partyInvitationDescription')}</p>
            </Block2>
          </LetterBlockDiv>
          <LetterBlockDiv>
            <Block3
              data-aos="fade-left"
              onClick={() => handleClick('L03_Reanudar')}
            >
              <h4>{t('freeLetters.resumeFriendship')}</h4>
              <p>{t('freeLetters.resumeFriendshipDescription')}</p>
            </Block3>
          </LetterBlockDiv>
        </FreeLetterWrapper>
        <Modal
          title={t('freelettersignupmodal.title')}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <p>{t('freelettersignupmodal.bodytext')}</p>
          <Link to={`/${currentLanguage}/Signup`}>
            <FreeSignupButton onClick={handleSignUp}>
              {t('freelettersignupmodal.signupbtn')}
            </FreeSignupButton>
          </Link>
        </Modal>
      </LetterContainer>
    </React.Fragment>
  );
};

export default FreeLetters;
