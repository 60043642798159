import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "../locales/en.json";
import esTranslation from "../locales/es.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: enTranslation },
      es: { translation: esTranslation },
    },
    fallbackLng: "es", // Default language if translation not found
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  })
  .then(() => {
    // Change language to Spanish upon initialization
    i18n.changeLanguage("es");
  });

export default i18n;
