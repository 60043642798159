import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import {
  ButtonWrapper,
  ContentWrapper,
  FormWrapperDiv,
  FullWidthInput,
  FullWidthInputBox,
  InputField,
  InputFieldBlock,
  InputFieldDiv,
  LanguageSelectDiv,
  PremiumSelectBlock,
  PresentationContainer,
  PurchaseButton,
  RenunContentDiv1,
  SubmitButton,
} from '../../PresentationLetter/PresentationLetter.styles.js';
import { LoadingOutlined } from '@ant-design/icons'; // Import LoadingOutlined
import axios from 'axios';

import Select from 'react-select';
import countries from 'react-select-country-list';
import {
  Radio,
  Form,
  Input,
  DatePicker,
  Button,
  message,
  Spin,
  Modal,
} from 'antd';
import { Store } from '../../../Store.js';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader.js';
import i18n from '../../i18n.js';
const { Option } = Select;

const CartadeAmor = () => {
  const { t } = useTranslation(); // Initializing the useTranslation hook  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const [loading, setLoading] = useState(true);
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const [hasPaid, setHasPaid] = useState(true);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalCancel = () => {
    setIsModalVisible(false);
    window.scrollTo(0, document.body.scrollHeight);
  };

  useEffect(() => {
    if (hasPaid === true) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [hasPaid]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState(() => {
    const storedData = JSON.parse(
      localStorage.getItem(`formData_${window.location.pathname}`)
    );
    return (
      storedData || {
        name: '',
        lastName: '',
        Namerecipient: '',
        PersonalDetails: '',
        country: '',
        city: '',
        email: '',
        language: 'spanish',
      }
    );
  });
  useEffect(() => {
    localStorage.setItem(
      `formData_${window.location.pathname}`,
      JSON.stringify(formData)
    );
  }, [formData]);
  useEffect(() => {
    if (!state.userInfo && !state.userInfo?.isActivated) {
      navigate(`/${currentLanguage}/Login`);
    } else {
      checkFormAndPaymentStatus();
    }
  }, [navigate, state.userInfo]);

  const checkFormAndPaymentStatus = async () => {
    try {
      const submittedFormResponse = await axios.get(
        `https://elescritor-io-22.onrender.com/api/users/${state.userInfo._id}/hasSubmittedForm`
      );
      setHasSubmittedForm(
        submittedFormResponse.data.hasSubmittedPartnerChildrenLoveLetter
      );

      const paidResponse = await axios.get(
        `https://elescritor-io-22.onrender.com/api/orders/${state.userInfo._id}/isPaid/L18_Amor`,
        {
          headers: {
            Authorization: `Bearer ${state.userInfo.token}`,
          },
        }
      );
      setHasPaid(paidResponse.data.hasPaid);
    } catch (error) {
      console.error('Error checking form and payment status:', error);
      // Handle error...
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/partnerchildrenloveletter',
        {
          userId: state.userInfo._id,
          formData: formData,
          formType: 'PartnerorChildrenLove',
          language: formData.language,
          formSubmitted: true, // Adding formSubmitted status
        }
      );

      if (response.status === 201) {
        message.success('Form submitted successfully.');
        setHasSubmittedForm(true);

        const currentLanguagePrefix = window.location.pathname.split('/')[1];
        const languagePrefix =
          currentLanguagePrefix === 'es' || currentLanguagePrefix === 'en'
            ? `/${currentLanguagePrefix}`
            : '';

        navigate(`${languagePrefix}/Thankyoumessage`);

        setFormData({
          name: '',
          lastName: '',
          Namerecipient: '',
          PersonalDetails: '',
          country: '',
          city: '',
          email: '',
          language: 'spanish',
        });
      } else {
        message.error('Failed to submit form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error.response.data.message);
      message.error(error.response.data.message || 'Failed to submit form.');
    } finally {
      setLoading(false);
    }
  };
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setFormData({ ...formData, language: selectedLanguage });
  };

  const currentLanguage = i18n.language; // get the current language

  const handlePurchase = (letterName, letterPrice) => {
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { letterName, letterPrice },
    });
    localStorage.setItem(
      'selectedLetter',
      JSON.stringify({ letterName, letterPrice })
    );
    navigate(`/${currentLanguage}/SelectPaymentMethod`);
  };
  const countryOptions = countries().getData();
  if (loading) {
    return (
      <div
        className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />;
      </div>
    );
  }
  return (
    <React.Fragment>
      <Form onFinish={handleSubmit}>
        <PresentationContainer className="PresentationContainerMob">
          <ContentWrapper className="ContentWrapperMob">
            <RenunContentDiv1 className="ContentDiv1Mob">
              <div>
                <h1>
                  {t('LoveLetter.Form.title')} &nbsp;
                  <span>
                    {t('LoveLetter.Form.title2')}
                    <br />
                    {t('LoveLetter.Form.title3')}
                  </span>
                </h1>
              </div>
              <div>
                <p>{t('LoveLetter.Form.description')}</p>
                <p>
                  <strong>{t('LoveLetter.Form.Strong')}:</strong>{' '}
                  {t('LoveLetter.Form.Note')}
                </p>
              </div>
            </RenunContentDiv1>
          </ContentWrapper>
          <PremiumSelectBlock className="PremiumSelectBlockMob">
            <h2>
              {t('LoveLetter.selection')} <span>{t('LoveLetter.adapta')}</span>
            </h2>
          </PremiumSelectBlock>

          <FormWrapperDiv className="FormWrapperDivMob">
            <div className="formtitlepremium">
              <h3>{t('LoveLetter.Formheading')}</h3>
            </div>

            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">{t('LoveLetter.yourName')}</label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  name="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {t('LoveLetter.yourLastName')}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {t('LoveLetter.Namerecipient')}
                </label>
                <FullWidthInput
                  type="text"
                  className="InputFieldMob"
                  name="Namerecipient"
                  value={formData.Namerecipient}
                  onChange={(e) =>
                    setFormData({ ...formData, Namerecipient: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {t('LoveLetter.PersonalDetails')}
                </label>
                <FullWidthInputBox
                  type="text"
                  className="InputFieldMob"
                  name="PersonalDetails"
                  value={formData.PersonalDetails}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      PersonalDetails: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv></InputFieldDiv>

            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">{t('LoveLetter.country')}</label>
                <Form.Item
                  name="country"
                  className="InputFieldMob"
                  rules={[
                    {
                      required: true,
                      message: t('LoveLetter.countryRequired.message'),
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginTop: '5px',
                    marginRight: '16px',
                  }}
                >
                  <Select
                    options={countryOptions}
                    isSearchable
                    placeholder={t('LoveLetter.country')}
                    value={formData.country}
                    onChange={(option) =>
                      setFormData({ ...formData, country: option.value })
                    }
                  />
                </Form.Item>{' '}
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">{t('LoveLetter.city')}</label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  name="city"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>

            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">{t('LoveLetter.email')}</label>
                <Form.Item
                  name="email"
                  className="InputFieldMob"
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginRight: '2%',
                    marginTop: '10px',
                  }}
                  rules={[
                    {
                      required: true,
                      message: t('LoveLetter.emailRequired.message'),
                    },
                    {
                      type: 'email',
                      message: t('LoveLetter.emailValidation.message'),
                    },
                  ]}
                >
                  <Input
                    style={{ height: '44px' }}
                    type="email"
                    placeholder={t('LoveLetter.email')}
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </Form.Item>{' '}
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <LanguageSelectDiv className="LanguageSelectDivMob">
                  <p>{t('LoveLetter.languageNote')}</p>
                  <div className="radioblocks">
                    <Radio.Group
                      value={formData.language}
                      onChange={handleLanguageChange}
                      name="language"
                    >
                      <Radio value="spanish">{t('Wedding.spanish')}</Radio>
                      <Radio value="english">{t('Wedding.english')}</Radio>
                    </Radio.Group>
                  </div>
                </LanguageSelectDiv>
              </InputFieldBlock>
            </InputFieldDiv>
            <ButtonWrapper>
              <h1>{t('presentationLetter.generatingLetterMessage')}</h1>

              <SubmitButton
                type="submit"
                className="submitbtn"
                disabled={!hasPaid || hasSubmittedForm}
                style={{
                  backgroundColor:
                    !hasPaid || hasSubmittedForm ? '#e0e0e0' : '',
                }}
                onClick={(e) => {
                  if (!hasPaid || hasSubmittedForm) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  t('presentationLetter.generateLetterButton')
                )}
              </SubmitButton>

              <PurchaseButton
                onClick={(e) => {
                  if (hasPaid && !hasSubmittedForm) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    handlePurchase(
                      t('L18_Amor'),
                      hasSubmittedForm ? 2.99 : 2.99
                    );
                    setHasPaid(true);
                    setHasSubmittedForm(false);
                  }
                }}
                className="purchasebtn"
                disabled={hasPaid && !hasSubmittedForm}
                style={{
                  backgroundColor:
                    hasPaid && !hasSubmittedForm ? '#e0e0e0' : '',
                }}
              >
                {t('LoveLetter.generateLetterButton')}
              </PurchaseButton>
            </ButtonWrapper>
          </FormWrapperDiv>
        </PresentationContainer>
      </Form>
      <Modal
        title={t('Modal.Title')}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <h2>
          <strong> {t('Future.Note')}:</strong> &nbsp;
          {t('Future.Pay')}
        </h2>
      </Modal>
    </React.Fragment>
  );
};

export default CartadeAmor;
