import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {
  Copyright,
  FooterContainer,
  FooterMenu,
  FooterMenup,
} from './Footer.styles';
import { Link } from 'react-router-dom';
import FooterLogo from '../../assets/Images/Footer_Logo.png';
import i18n from '../i18n';

const Footer = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language; // get the current language

  return (
    <FooterContainer className="FooterContainerMob">
      <FooterMenu className="FooterMenuMob">
        <Link className="Link">{t('footer.links.home')}</Link>
        <Link to={`/${currentLanguage}/About`} className="Link">
          {t('footer.links.aboutUs')}
        </Link>
        <Link to={`/${currentLanguage}/Contact`} className="Link">
          {t('footer.links.contact')}
        </Link>
        <Link to={`/${currentLanguage}/FAQ`} className="Link">
          {t('footer.links.faq')}
        </Link>
      </FooterMenu>
      <Copyright className="Copyright">
        <img src={FooterLogo} alt="" />
        <p>{t('footer.copyright.text')}</p>
        <h6>{t('footer.copyright.rightsReserved')}</h6>
      </Copyright>
      <FooterMenup className="FooterMenupp">
        <Link to={`/${currentLanguage}/TermsandConditions`} className="Linkp">
          {t('footer.menu.termsAndConditions')}
        </Link>
        <Link to={`/${currentLanguage}/UsagePolicies`} className="Linkp">
          {t('footer.menu.usagePolicies')}
        </Link>
        <Link to={`/${currentLanguage}/PrivacyPolicy`} className="Linkp">
          {t('footer.menu.privacyPolicies')}
        </Link>
      </FooterMenup>
    </FooterContainer>
  );
};

export default Footer;
