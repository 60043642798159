import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import { PremiumSelectBlock } from '../PresentationLetter/PresentationLetter.styles';
import { PCContainer, PCParagraph } from './PrivacyPolicy.styles';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the component when it mounts
  }, []);
  return (
    <PCContainer>
      <PremiumSelectBlock className="Nosotros">
        <h2>
          <span>{t('privacyPolicy.title')}</span>
        </h2>
      </PremiumSelectBlock>
      <PCParagraph>
        <h6><strong>{t('privacyPolicy.lastUpdate')}</strong></h6>
        <p className="tcpara">{t('privacyPolicy.description')}</p> <br/>
      </PCParagraph>
      {t('privacyPolicy.sections', { returnObjects: true }).map(
        (section, index) => (
          <PCParagraph key={index}>
            <h4>
              <strong>{section.title}</strong>
            </h4>
            <p className="tcpara">{section.content}</p>
            <br />
            <br />
          </PCParagraph>
        )
      )}
    </PCContainer>
  );
};

export default PrivacyPolicy;
