import styled from 'styled-components';

export const HeroContainer = styled.div`
  /* width: 100%; */
  padding: 96px 144px 96px 144px;
  display: flex;
  justify-content: space-between;
`;
export const HeroContainerMob = styled.div`
  /* width: 100%; */
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
`;
export const HeroHeaderMob = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding: 30px;
`;

export const HeroTitle = styled.h1`
  font-family: Inter;
  font-size: 64px;
  font-weight: 600;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: left;
  color: #4d4d4d;
  margin-bottom: 0px !important;
`;

export const HeroTitle2 = styled.span`
  font-family: Inter;
  font-size: 64px;
  font-weight: 600;
  /* line-height: 76px; */
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px !important;
  color: #4caf4f;
`;

export const HeroPara = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
`;
export const HeroTitleMob = styled.h1`
  font-family: Inter;
  font-size: 34px !important;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  color: #4d4d4d;
  margin-bottom: 0px !important;
`;

export const HeroTitle2Mob = styled.span`
  font-family: Inter;
  font-size: 34px !important;
  font-weight: 600;
  /* line-height: 76px; */
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px !important;
  color: #4caf4f;
`;

export const HeroParaMob = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
`;

export const HeroButton = styled.button`
  width: 193px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Brand-Primary, #4caf4f);
  gap: 10px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
`;

export const HeroImageWrapper = styled.div`
  display: flex;
`;
export const HeroImage = styled.img``;

export const HeroImageMob = styled.img`
  width: 300px;
  height: 300px;
`;
