import styled from 'styled-components';

export const FooterContainer = styled.div`
  background: var(--Neutral-Silver, #f5f7fa);
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const FooterMenu = styled.ul`
  width: 400px;
  display: flex !important;
  justify-content: space-between;
  list-style-type: none;
`;
export const FooterMenup = styled.ul`
  width: 500px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* padding: 25px; */

  img {
    margin-bottom: 10px;
  }

  p {
    margin-top: 10px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: #89939e;
  }
  h6 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: #89939e;
  }
`;
