import styled from 'styled-components';

export const LetterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 152px 48px 152px;
`;

export const MainHeading = styled.h1`
  color: var(--Neutral-D_Grey, #4d4d4d);
  text-align: center;
  font-family: Inter;
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 3.6px;
  width: 64vw;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`;

export const TopPara = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #717171;
`;

export const FreeLetterWrapper = styled.div`
  display: flex;
  /* padding: 20px; */
  /* width: 58vw; */
`;

export const LetterHeading = styled.h2`
  font-family: Inter;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  width: 250px;
`;
export const LetterSpan = styled.span`
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
`;

export const LetterBlockDiv = styled.div`
  display: flex;
`;
export const TitleBlock = styled.div`
  height: 228px;
  display: flex;
  align-items: center;
`;
export const Block1 = styled.div`
  display: flex;
  width: 299px !important;

  flex-direction: column;
  align-items: center;
  height: 228px !important;
  padding: 20px;
  border-radius: 8px;
  background: var(--Neutral-Silver, #f5f7fa);
  box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.2);
  &:hover {
    background: var(--Tint-T4, #c8e6c9);
    cursor: pointer;
  }
  h4 {
    //styleName: Heading/Headline 3;
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #4d4d4d;
    margin-bottom: 0px !important;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;

    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #717171;
    width: 251px;
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
`;
export const Block2 = styled.div`
  display: flex;
  width: 299px !important;
  height: 228px !important;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: var(--Neutral-Silver, #f5f7fa);
  box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.2);

  &:hover {
    background: var(--Tint-T4, #c8e6c9);
    cursor: pointer;
  }
  h4 {
    //styleName: Heading/Headline 3;
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #4d4d4d;
    /* margin-bottom: 0px !important; */
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #717171;
    width: 251px;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
  }
`;
export const Block3 = styled.div`
  display: flex;
  width: 299px !important;
  height: 228px !important;
  /* margin-left: -5px; */
  flex-direction: column;
  align-items: center;
  padding: 20px;

  border-radius: 8px;
  background: var(--Neutral-Silver, #f5f7fa);
  box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.2);

  &:hover {
    background: var(--Tint-T4, #c8e6c9);
    cursor: pointer;
  }
  h4 {
    //styleName: Heading/Headline 3;
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #4d4d4d;
    /* margin-bottom: 0px !important; */
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #717171;
    width: 251px;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }
`;
