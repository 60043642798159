import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Spin, Input } from 'antd';
import {
  LoginContainer,
  LoginFieldDiv,
  LoginWrapper,
  Loginbuttondiv,
} from './Login/Login.styles';
import { LoadingOutlined } from '@ant-design/icons';

import LoginImage from '../assets/Images/Hero/HeroImage.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../UsefulFunctions';
import { SubmitButtonForget } from './PresentationLetter/PresentationLetter.styles';
import Loader from './Loader/Loader';
import i18n from './i18n';

const ForgotPasswordScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const currentLanguage = i18n.language; // get the current language

  useEffect(() => {
    if (userInfo) {
      navigate('/Login');
    }
  }, [navigate, userInfo]);

  const SubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the form is submitted

    try {
      const { data } = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/forget-password',
        {
          email,
        }
      );
      message.success(data.message);
    } catch (err) {
      message.error(getError(err));
    } finally {
      setLoading(false); // Set loading to false after the request is completed
    }
  };
  const setEmailWithCapital = (email) => {
    const capitalizedEmail = email.charAt(0).toUpperCase() + email.slice(1);
    setEmail(capitalizedEmail);
  };

  return (
    <LoginContainer className="SignupContainerMob">
      <LoginWrapper className="SignupWrapperMob">
        <div>
          <img src={LoginImage} alt="" />
        </div>
        <LoginFieldDiv className="InputFieldBlockMob">
          <>
            <h1>{t('Future.ForgetPasswordHeading')}</h1>
            <div>
              <form onSubmit={SubmitHandler} className="forgetforrm">
                <label>{t('Login.yourEmail')}</label>
                <Input
                  className="InputFieldMobss"
                  style={{
                    height: '55px',
                    width: '100%',
                    border: '1px solid #d9d9d9',
                  }}
                  type="text"
                  value={email}
                  onChange={(e) => setEmailWithCapital(e.target.value)}
                />
                <Loginbuttondiv>
                  {loading ? (
                    <Loader />
                  ) : (
                    <SubmitButtonForget className="logbtn" type="submit">
                      {t('Future.Submit')}
                    </SubmitButtonForget>
                  )}
                </Loginbuttondiv>
              </form>
            </div>
          </>
        </LoginFieldDiv>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default ForgotPasswordScreen;
