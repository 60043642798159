import React, { useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import axios from 'axios';
import { getError } from '../UsefulFunctions';
import { Spin, Table, Button, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return {
        ...state,
        orders: state.orders.map((order) =>
          order._id === action.payload.orderId
            ? { ...order, loading: true }
            : order
        ),
      };
    case 'UPDATE_SUCCESS':
      return {
        ...state,
        orders: state.orders.map((order) =>
          order._id === action.payload.orderId
            ? { ...order, loading: false, isPaid: true }
            : order
        ),
      };
    case 'UPDATE_FAIL':
      return {
        ...state,
        orders: state.orders.map((order) =>
          order._id === action.payload.orderId
            ? { ...order, loading: false }
            : order
        ),
      };
    default:
      return state;
  }
};

const OrderListScreen = () => {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    orders: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };

    fetchData();
  }, [userInfo]);

  const handleDetailsClick = async (orderId, letterName) => {
    try {
      // Set loading to true for this order
      dispatch({
        type: 'UPDATE_REQUEST',
        payload: { orderId },
      });

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(`/api/orders/${orderId}`, {}, config);
      // Update local state or refetch orders if needed
      message.success('Order status updated successfully.');
      // Set loading to false and update the order when the API request is successful
      dispatch({
        type: 'UPDATE_SUCCESS',
        payload: { orderId },
      });
    } catch (err) {
      message.error('Failed to update order status.');
      // Set loading to false and show an error message
      dispatch({ type: 'UPDATE_FAIL', payload: getError(err) });
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'USER',
      dataIndex: 'user',
      key: 'user',
      render: (user) => (user ? user.name : ''),
    },
    {
      title: 'LETTER NAME',
      dataIndex: 'letterName',
      key: 'letterName',
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        const formattedDate = new Date(createdAt).toLocaleDateString();
        return isNaN(new Date(createdAt)) ? 'Invalid Date' : formattedDate;
      },
    },

    {
      title: 'TOTAL',
      dataIndex: 'letterPrice',
      key: 'letterPrice',
      render: (letterPrice) => letterPrice.toFixed(2),
    },
    {
      title: 'PAID',
      dataIndex: 'isPaid',
      key: 'isPaid',
      render: (isPaid, record) => (
        <span style={{ color: isPaid ? 'green' : 'red' }}>
          {record.loading ? 'Updating...' : isPaid ? 'Paid' : 'Not Paid'}
        </span>
      ),
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (text, record) => (
        <span>
          {record.isPaid ? (
            <Button type="link">Granted</Button>
          ) : (
            <Button
              type="link"
              onClick={() => handleDetailsClick(record._id, record.letterName)}
            >
              Grant Letter
            </Button>
          )}
        </span>
      ),
    },
  ];

  // CSV data
  const csvData = orders.map((order) => ({
    ID: order._id,
    USER: order.user ? order.user.name : '',
    'LETTER NAME': order.letterName,
    DATE: new Date(order.createdAt).toLocaleDateString(),
    TOTAL: order.letterPrice.toFixed(2),
    PAID: order.isPaid ? 'Paid' : 'Not Paid', // Use the same logic as in the table's render function
  }));

  return (
    <React.Fragment>
      <div className="table-container">
        <h1>Orders</h1>
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            <Table
              columns={columns}
              dataSource={orders}
              rowKey="_id"
              className="custom-table" // Add custom class here
            />
            <Button type="primary" style={{ marginTop: '10px' }}>
              <CSVLink data={csvData} filename={'orders.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          </div>
        )}
      </div>
      <div className="table-containermob">
        <h1>Orders</h1>
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={orders}
                rowKey="_id"
                className="custom-table" // Add custom class here
              />
            </div>

            <Button type="primary" style={{ marginTop: '10px' }}>
              <CSVLink data={csvData} filename={'orders.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default OrderListScreen;
