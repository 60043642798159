import styled from 'styled-components';
export const NavbarContainer = styled.div`
  width: 100%;
  background-color: #f5f7fa;
  color: #18191f;
  height: 84px !important;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
`;
export const NavbarLogo = styled.img``;
export const Logo = styled.a`
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
`;

export const SMenu = styled.ul`
  width: 647px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
`;

export const MenuItem = styled.li`
  margin-right: 1rem;
  font-size: 1rem;
`;

export const RegisterLoginWrapper = styled.div`
  display: flex;
  list-style-type: none;
`;
export const RegisterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Brand-Primary, #4caf4f);
  color: #ffffff;
  border: none;
  outline: none;
  width: 154px;
  height: 40px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;
export const RegisterButtonMob = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Brand-Primary, #4caf4f);
  color: #ffffff;
  border: none;
  outline: none;
  width: 120px;
  height: 40px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;
