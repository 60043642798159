import React, { useState, useEffect, useContext } from 'react';
import { Select, Dropdown, Menu, message } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import './Navbar.styles.js';
import {
  Logo,
  SMenu,
  Nav,
  NavbarContainer,
  NavbarLogo,
  RegisterButton,
  RegisterLoginWrapper,
  RegisterButtonMob,
} from './Navbar.styles.js';
import NavLogo from '../../assets/Images/Navbar/NavbarLogo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Globe from '../../assets/Images/global.svg';
import { Store } from '../../Store.js';
import Heroimg from '../../assets/Images/Hero/HeroImage.png';

const { Option } = Select;

const Navbar = ({ onLanguageChange, selectedLanguage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);
  const [loggedIn, setLoggedIn] = useState(false);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowLanguageDropdown(false);
    setCurrentLanguage(lng);
    onLanguageChange(lng);
  };

  const handleLanguageChange = (value) => {
    changeLanguage(value);
  };

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };

  const handleSignOut = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('paymentMethod');

    navigate(`/${currentLanguage}/Login`);
  };

  const languageMenu = (
    <Menu onClick={({ key }) => handleLanguageChange(key)}>
      {currentLanguage === 'en' ? (
        <Menu.Item key="es">Spanish</Menu.Item>
      ) : (
        <Menu.Item key="en">English</Menu.Item>
      )}
    </Menu>
  );
  const handleSelectChange = (value) => {
    if (value === 'Dashboard') {
      navigate(`/${currentLanguage}/admin/dashboard`);
    } else if (value === 'Orders') {
      navigate(`/${currentLanguage}/admin/orders`);
    } else if (value === 'Users') {
      navigate(`/${currentLanguage}/admin/users`);
    }
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <React.Fragment>
      <NavbarContainer className="nav-web">
        <Nav>
          <Logo>
            <Link to={`/${currentLanguage}`}>
              <NavbarLogo src={NavLogo} alt="Logo" />
            </Link>
          </Logo>

          <SMenu>
            <Link to={`/${currentLanguage}`} className="Link">
              {t('navbar.home')}{' '}
            </Link>
            <Link to={`/${currentLanguage}/About`} className="Link">
              {t('navbar.about')}
            </Link>
            <Link to={`/${currentLanguage}/Contact`} className="Link">
              {t('navbar.contact')}
            </Link>
            <Link to={`/${currentLanguage}/FAQ`} className="Link">
              {t('navbar.faqs')}
            </Link>

            <Dropdown
              overlay={languageMenu}
              placement="bottomCenter"
              trigger={['click']}
              visible={showLanguageDropdown}
              onVisibleChange={toggleLanguageDropdown}
            >
              <img
                src={Globe}
                alt="Language"
                style={{ cursor: 'pointer', marginTop: '-5px' }}
              />
            </Dropdown>
            {(userInfo && userInfo.isActivated) ||
            (userInfo && userInfo.isAdmin) ? (
              ''
            ) : (
              <Link to={`/${currentLanguage}/Login`} className="LoginLinks">
                {t('navbar.login')}
              </Link>
            )}
            {(userInfo && !userInfo.isActivated) ||
            (userInfo && userInfo.isAdmin) ? (
              <Select
                defaultValue="Admin"
                style={{ width: 120 }}
                onChange={handleSelectChange}
              >
                <Option value="Dashboard">Dashboard</Option>
                <Option value="Orders">Orders</Option>
                <Option value="Users">View Users</Option>
              </Select>
            ) : (
              ''
            )}
          </SMenu>
          {(userInfo && userInfo.isActivated) ||
          (userInfo && userInfo.isAdmin) ? (
            <>
              <h4 className="usernames">{userInfo.name}</h4>
              <Select
                defaultValue="Signout"
                onChange={handleSignOut}
                style={{ width: 120 }}
              >
                <Option value="signout">Signout</Option>
              </Select>
            </>
          ) : (
            <>
              <RegisterLoginWrapper>
                <RegisterButton>
                  <Link
                    className="signuplinks"
                    to={`/${currentLanguage}/Signup`}
                  >
                    {t('navbar.register')}
                  </Link>
                </RegisterButton>
              </RegisterLoginWrapper>
            </>
          )}
        </Nav>
      </NavbarContainer>
      <NavbarContainer className="nav-mob">
        <Nav className="navmob">
          <Logo>
            <Link to={`/${currentLanguage}`}>
              {/* <NavbarLogo src={Heroimg} alt="Logo" className="navlogo" /> */}
            </Link>
          </Logo>
          {userInfo ? (
            ''
          ) : (
            <Link to={`/${currentLanguage}/Login`} className="LoginLinks">
              {t('navbar.login')}
            </Link>
          )}
          {userInfo ? (
            <>
              <h4 className="usernames">{userInfo.name}</h4>
              <Select
                defaultValue="Signout"
                onChange={handleSignOut}
                style={{ width: 80 }}
              >
                <Option value="signout">Signout</Option>
              </Select>
            </>
          ) : (
            <>
              <RegisterLoginWrapper>
                <RegisterButtonMob>
                  <Link
                    className="signuplinks"
                    to={`/${currentLanguage}/Signup`}
                  >
                    {t('navbar.register')}
                  </Link>
                </RegisterButtonMob>
              </RegisterLoginWrapper>
            </>
          )}
          <Dropdown
            overlay={languageMenu}
            placement="bottomCenter"
            trigger={['click']}
            visible={showLanguageDropdown}
            onVisibleChange={toggleLanguageDropdown}
          >
            <img
              className="mobimg"
              src={Globe}
              alt="Language"
              style={{ cursor: 'pointer', marginTop: '-5px' }}
            />
          </Dropdown>
          <div className="menu-icon" onClick={handleMenuToggle}>
            <MenuOutlined />
          </div>

          {/* <SMenu>
            <Link to="/" className="Link">
              {t('navbar.home')}{' '}
            </Link>
            <Link to="/Nosotros" className="Link">
              {t('navbar.about')}
            </Link>
            <Link to="/Contact" className="Link">
              {t('navbar.contact')}
            </Link>
            <Link to="/PreguntasFrecuentes" className="Link">
              {t('navbar.faqs')}
            </Link>

            <Dropdown
              overlay={languageMenu}
              placement="bottomCenter"
              trigger={['click']}
              visible={showLanguageDropdown}
              onVisibleChange={toggleLanguageDropdown}
            >
              <img
                src={Globe}
                alt="Language"
                style={{ cursor: 'pointer', marginTop: '-5px' }}
              />
            </Dropdown>
            {userInfo ? (
              ''
            ) : (
              <Link to="/Login" className="LoginLinks">
                {t('navbar.login')}
              </Link>
            )}
            {userInfo && userInfo.isAdmin && (
              <Select
                defaultValue="Admin"
                style={{ width: 120 }}
                onChange={handleSelectChange}
              >
                <Option value="Dashboard">Dashboard</Option>
                <Option value="Orders">Orders</Option>
                <Option value="Users">View Users</Option>
              </Select>
            )}
          </SMenu> */}
          {/* {userInfo ? (
            <>
              <h4 className="usernames">{userInfo.name}</h4>
              <Select
                defaultValue="Signout"
                onChange={handleSignOut}
                style={{ width: 120 }}
              >
                <Option value="signout">Signout</Option>
              </Select>
            </>
          ) : (
            <>
              <RegisterLoginWrapper>
                <RegisterButton>
                  <Link className="signuplinks" to="/Signup">
                    {t('navbar.register')}
                  </Link>
                </RegisterButton>
              </RegisterLoginWrapper>
            </>
          )} */}
        </Nav>
        {isMenuOpen && (
          <div className="mob-viewcontainer">
            <Menu mode="vertical" onClick={closeMenu} className="menuwidth">
              <Menu.Item key="home">
                <Link to={`/${currentLanguage}`} className="Link">
                  {t('navbar.home')}{' '}
                </Link>
              </Menu.Item>
              <Menu.Item key="Nosotros">
                <Link to={`/${currentLanguage}/About`} className="Link">
                  {t('navbar.about')}
                </Link>
              </Menu.Item>
              <Menu.Item key="contact">
                <Link to={`/${currentLanguage}/Contact`} className="Link">
                  {t('navbar.contact')}
                </Link>
              </Menu.Item>
              <Menu.Item key="faqs">
                <Link to={`/${currentLanguage}/Faq`} className="Link">
                  {t('navbar.faqs')}
                </Link>
              </Menu.Item>
              {userInfo && userInfo.isAdmin && (
                <Select
                  defaultValue="Admin"
                  style={{ width: 120 }}
                  onChange={handleSelectChange}
                >
                  <Option value="Dashboard">Dashboard</Option>
                  <Option value="Orders">Orders</Option>
                  <Option value="Users">View Users</Option>
                </Select>
              )}
            </Menu>
          </div>
        )}
      </NavbarContainer>
    </React.Fragment>
  );
};

export default Navbar;
