import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Spin, Input } from 'antd';
import {
  LoginContainer,
  LoginFieldDiv,
  LoginWrapper,
  Loginbuttondiv,
} from './Login/Login.styles';
import { LoadingOutlined } from '@ant-design/icons';

import LoginImage from '../assets/Images/Hero/HeroImage.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../UsefulFunctions';
import { SubmitButtonForget } from './PresentationLetter/PresentationLetter.styles';
import Loader from './Loader/Loader';

const ActivateAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    if (userInfo && userInfo.isActivated) {
      navigate('/');
    }
  }, [navigate, userInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/verify-otp',
        {
          email: userInfo.email,
          otp: parseInt(otp),
        }
      );
      const updatedUserInfo = { ...userInfo, isActivated: true };

      ctxDispatch({ type: 'USER_SIGNIN', payload: updatedUserInfo });
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      message.success('Account activated successfully.');
      navigate('/');
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <LoginWrapper>
        <div>
          <img src={LoginImage} alt="" />
        </div>
        <LoginFieldDiv>
          <>
            <h1>Activate Account</h1>
            <div>
              <form onSubmit={handleSubmit}>
                <label>OTP</label>
                <Input
                  style={{
                    height: '55px',
                    width: '100%',
                    border: '1px solid #d9d9d9',
                  }}
                  type="text" // Change type to "text"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                />
                <Loginbuttondiv>
                  {loading ? (
                    <Loader />
                  ) : (
                    <SubmitButtonForget className="logbtn" type="submit">
                      Activate
                    </SubmitButtonForget>
                  )}
                </Loginbuttondiv>
              </form>
            </div>
          </>
        </LoginFieldDiv>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default ActivateAccount;
