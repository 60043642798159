import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonWrapper,
  ContentDiv1,
  ContentDiv2,
  ContentDivBlock,
  ContentWrapper,
  FormWrapperDiv,
  InputField,
  InputFieldBlock,
  InputFieldDiv,
  LanguageSelectDiv,
  PresentationContainer,
  PurchaseButton,
  SubmitButton,
} from './PresentationLetter.styles';
import { Radio, Form, Input, DatePicker, message, Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'; // Import LoadingOutlined
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countries from 'react-select-country-list';
import { Store } from '../../Store';
import axios from 'axios';
import i18n from '../i18n';

const { Option } = Select;

const PresentationLetter1 = () => {
  const { t } = useTranslation();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const currentLanguage = i18n.language; // get the current language

  const [formData, setFormData] = useState(() => {
    const storedData = JSON.parse(
      localStorage.getItem(`formData_${window.location.pathname}`)
    );
    return (
      storedData || {
        name: '',
        lastName: '',
        recipientName: '',
        companyName: '',
        country: '',
        city: '',
        positionAppliedFor: '',
        startDate: null,
        yearsOfExperience: 0,
        relatedExperience: '',
        relatedExperienc2: '',
        relatedExperienc3: '',
        relatedExperience4: '',
        email: '',
        language: 'spanish',
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      `formData_${window.location.pathname}`,
      JSON.stringify(formData)
    );
  }, [formData]);
  const [loading, setLoading] = useState(false);
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalCancel = () => {
    setIsModalVisible(false);
    window.scrollTo(0, document.body.scrollHeight);
  };

  useEffect(() => {
    if (hasPaid === true) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [hasPaid]);
  useEffect(() => {
    if (!state.userInfo && !state.userInfo?.isActivated) {
      navigate(`/${currentLanguage}/Login`);
    } else {
      checkFormAndPaymentStatus();
    }
  }, [navigate, state.userInfo]);

  const checkFormAndPaymentStatus = async () => {
    try {
      const submittedFormResponse = await axios.get(
        `https://elescritor-io-22.onrender.com/api/users/${state.userInfo._id}/hasSubmittedForm`
      );
      setHasSubmittedForm(submittedFormResponse.data.hasSubmittedCoverLetter);

      const paidResponse = await axios.get(
        `https://elescritor-io-22.onrender.com/api/orders/${state.userInfo._id}/isPaid/L01_Presentacion`,
        {
          headers: {
            Authorization: `Bearer ${state.userInfo.token}`,
          },
        }
      );
      setHasPaid(paidResponse.data.hasPaid);
      console.log('paidresponse', hasPaid);
    } catch (error) {
      console.error('Error checking form and payment status:', error);
      // Handle error...
    }
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setFormData({ ...formData, language: selectedLanguage });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/coverletterForm',
        {
          userId: state.userInfo._id,
          formData: formData,
          formType: 'Cover',
          language: formData.language,
          formSubmitted: true,
        }
      );

      if (response.status === 201) {
        message.success('Form submitted successfully.');
        setHasSubmittedForm(true);

        const currentLanguagePrefix = window.location.pathname.split('/')[1];
        const languagePrefix =
          currentLanguagePrefix === 'es' || currentLanguagePrefix === 'en'
            ? `/${currentLanguagePrefix}`
            : '';

        navigate(`${languagePrefix}/Thankyoumessage`);

        setFormData({
          name: '',
          lastName: '',
          recipientName: '',
          companyName: '',
          country: '',
          city: '',
          positionAppliedFor: '',
          startDate: null,
          yearsOfExperience: 0,
          relatedExperience: '',
          email: '',
          language: 'spanish',
        });
      } else {
        message.error('Failed to submit form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error.response.data.message);
      message.error(error.response.data.message || 'Failed to submit form.');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = (letterName, letterPrice) => {
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { letterName, letterPrice },
    });
    navigate(`/${currentLanguage}/SelectPaymentMethod`);
  };
  const countryOptions = countries().getData();
  return (
    <React.Fragment>
      <Form onFinish={handleSubmit}>
        <PresentationContainer className="PresentationContainerMob">
          <ContentWrapper className="ContentWrapperMob">
            <ContentDiv1 className="ContentDiv1Mob">
              <h1>
                {t('Form.title')} &nbsp;
                <span>
                  {t('Form.title2')}
                  <br />
                  {t('Form.title3')}
                </span>
                <p>{t('Form.description')}</p>
                <p>
                  <strong>{t('Form.Strong')}</strong> {t('Form.Note')}
                </p>
              </h1>
            </ContentDiv1>
            <ContentDiv2 className="ContentDiv2Mob">
              <h1>
                {t('Form.FreeHeading')}
                <span> {t('Form.Free')}</span>
              </h1>
              <ContentDivBlock>
                <h3> {t('Form.PresentationHeading')}</h3>
                <p>{t('Form.PresentationDescription')}</p>
              </ContentDivBlock>
            </ContentDiv2>
          </ContentWrapper>
          <FormWrapperDiv className="FormWrapperDivMob">
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  *{t('presentationLetter.yourName')}
                </label>
                <InputField
                  className="InputFieldMob"
                  name="name"
                  required
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.yourLastName')}{' '}
                </label>
                <InputField
                  name="lastName"
                  className="InputFieldMob"
                  required
                  type="text"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  *{t('presentationLetter.recipientName')} <br />
                </label>
                <InputField
                  name="recipientName"
                  className="InputFieldMob"
                  required
                  type="text"
                  value={formData.recipientName}
                  onChange={(e) =>
                    setFormData({ ...formData, recipientName: e.target.value })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.companyName')}{' '}
                </label>
                <InputField
                  name="companyName"
                  className="InputFieldMob"
                  type="text"
                  required
                  value={formData.companyName}
                  onChange={(e) =>
                    setFormData({ ...formData, companyName: e.target.value })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.country')}{' '}
                </label>
                <Form.Item
                  required
                  name="country"
                  className="InputFieldMob"
                  rules={[
                    {
                      required: true,
                      message: t('presentationLetter.countryRequired.message'),
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginTop: '5px',
                    marginRight: '16px',
                  }}
                >
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={(selectedOption) =>
                      setFormData({
                        ...formData,
                        country: selectedOption.value,
                      })
                    }
                    options={countryOptions}
                    isSearchable
                    placeholder={t('presentationLetter.country')}
                  />
                </Form.Item>
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.city')}{' '}
                </label>
                <InputField
                  name="city"
                  type="text"
                  className="InputFieldMob"
                  required
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      city: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.positionAppliedFor')}{' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  name="positionAppliedFor"
                  required
                  value={formData.positionAppliedFor}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      positionAppliedFor: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.anticipatedStartDate')}{' '}
                </label>
                <Form.Item
                  required
                  className="InputFieldMob"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: t(
                        'presentationLetter.startDateRequired.message'
                      ),
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginTop: '10px',
                    marginRight: '2%',
                  }}
                >
                  <DatePicker
                    name="startDate"
                    value={formData.startDate}
                    onChange={(selectedOption) =>
                      setFormData({
                        ...formData,
                        startDate: selectedOption.value,
                      })
                    }
                    format="DD/MM/YYYY"
                    style={{ width: '100%', height: '44px' }}
                  />
                </Form.Item>
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.yearsOfExperience')}{' '}
                </label>
                <InputField
                  type="number"
                  className="InputFieldMob"
                  name="yearsOfExperience"
                  required
                  value={formData.yearsOfExperience}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      yearsOfExperience: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.relatedExperience')} (optional){' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  value={formData.relatedExperience}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      relatedExperience: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.relatedExperience')} (optional){' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  value={formData.relatedExperience2}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      relatedExperience2: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.relatedExperience')} (optional){' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  value={formData.relatedExperience3}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      relatedExperienc3: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  {t('presentationLetter.relatedExperience')} (optional){' '}
                </label>
                <InputField
                  type="text"
                  className="InputFieldMob"
                  value={formData.relatedExperienc4}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      relatedExperienc4: e.target.value,
                    })
                  }
                />
              </InputFieldBlock>
            </InputFieldDiv>
            <InputFieldDiv className="InputFieldDivMob">
              <InputFieldBlock className="InputFieldBlockMob">
                <label className="inputlabel">
                  {' '}
                  *{t('presentationLetter.email')}{' '}
                </label>
                <Form.Item
                  name="email"
                  className="InputFieldMob"
                  required
                  style={{
                    display: 'inline-block',
                    width: '512px',
                    marginRight: '2%',
                    marginTop: '10px',
                  }}
                  rules={[
                    {
                      required: true,
                      message: t('presentationLetter.emailRequired.message'),
                    },
                    {
                      type: 'email',
                      message: t('presentationLetter.emailValidation.message'),
                    },
                  ]}
                >
                  <Input
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      })
                    }
                    style={{ height: '44px' }}
                    type="email"
                    placeholder={t('presentationLetter.email')}
                  />
                </Form.Item>{' '}
              </InputFieldBlock>
              <InputFieldBlock className="InputFieldBlockMob">
                <LanguageSelectDiv className="LanguageSelectDivMob">
                  <p>{t('presentationLetter.languageNote')}</p>
                  <div className="radioblocks">
                    <Radio.Group
                      value={formData.language}
                      onChange={handleLanguageChange}
                      name="language"
                    >
                      <Radio value="spanish">
                        {' '}
                        {t('presentationLetter.spanish')}{' '}
                      </Radio>
                      <Radio value="english">
                        {' '}
                        {t('presentationLetter.english')}{' '}
                      </Radio>
                    </Radio.Group>
                  </div>
                </LanguageSelectDiv>
              </InputFieldBlock>
            </InputFieldDiv>
            <ButtonWrapper>
              <h1>{t('presentationLetter.generatingLetterMessage')}</h1>

              <SubmitButton
                type="submit"
                className="submitbtn"
                disabled={hasSubmittedForm}
                style={{
                  backgroundColor: hasSubmittedForm ? '#e0e0e0' : '',
                }}
                onClick={(e) => {
                  if (hasSubmittedForm) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  t('presentationLetter.generateLetterButton')
                )}
              </SubmitButton>

              <PurchaseButton
                onClick={(e) => {
                  if (!hasSubmittedForm) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    handlePurchase(
                      t('L01_Presentacion'),
                      hasSubmittedForm ? 2.99 : 2.99
                    );
                  }
                }}
                className="purchasebtn"
                disabled={!hasSubmittedForm}
                style={{
                  backgroundColor: !hasSubmittedForm ? '#e0e0e0' : '',
                }}
              >
                {t('LoveLetter.generateLetterButton')}
              </PurchaseButton>
            </ButtonWrapper>
          </FormWrapperDiv>
        </PresentationContainer>
      </Form>
    </React.Fragment>
  );
};

export default PresentationLetter1;
