import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie'; // Import CookiesProvider
import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18n';
import { StoreProvider } from './Store';
import { HelmetProvider } from 'react-helmet-async';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiesProvider> {/* Wrap your App with CookiesProvider */}
      <StoreProvider>
        <HelmetProvider>
          <I18nextProvider i18n={i18n}>
            <PayPalScriptProvider deferLoading={true}>
              <Router>
                <App />
              </Router>
            </PayPalScriptProvider>
          </I18nextProvider>
        </HelmetProvider>
      </StoreProvider>
    </CookiesProvider>
  </React.StrictMode>
);

reportWebVitals();
