import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../Store';
import Form from 'react-bootstrap/Form';
import paypalbutton from './paypalbutton.png'; // Import the PayPal button image
import paycards from './paycards.jpg'; // Import the Stripe button image
import '../OrderScreen.css';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
const PaymentMethodScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentLanguage = i18n.language; // get the current language

  const { state, dispatch: ctxDispatch } = useContext(Store);

  const {
    letterCart: { paymentMethod },
  } = state;

  const [paymentMethodName, setPaymentMethod] = useState(
    paymentMethod || 'PayPal'
  );

  useEffect(() => {
    if (!state.userInfo) {
      navigate(`/${currentLanguage}/Login`);
    }
  }, [navigate, state.userInfo]);

  const handlePaymentMethodSelect = (selectedMethod) => {
    setPaymentMethod(selectedMethod);
    ctxDispatch({ type: 'SAVE_PAYMENT_METHOD', payload: selectedMethod });
    localStorage.setItem('paymentMethod', selectedMethod);
    navigate(`/${currentLanguage}/placeorder`);
  };

  return (
    <div className="paymentmethodcontainer">
      <div className="card">
        <div className="card-content">
          <h2 className="card-title"> {t('SelectPaymentScreen.title')}</h2>
          <Form className="btnsflex">
            <div className="lbl2">
              <label>{t('SelectPaymentScreen.paycard')}</label>
            </div>{' '}
            <img
              className="paypalbtn"
              src={paycards}
              alt="Stripe Button"
              onClick={() => handlePaymentMethodSelect('Stripe')}
            />
            <div className="lbl1">
              <label>{t('SelectPaymentScreen.OR')}</label>
            </div>
            <img
              className="paypalbtn"
              src={paypalbutton}
              alt="Stripe Button"
              onClick={() => handlePaymentMethodSelect('PayPal')}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodScreen;
