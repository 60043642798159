import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BottomPic from '../../assets/Images/BottomPic.png';
import {
  CommunityButton,
  CommunityContainer,
  CommunityContentDiv,
} from './Community.styles';

const Community = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
    <React.Fragment>
      <CommunityContainer className="CommunityContainerWeb">
        <Link to="/L22_Cuento">
          <img src={BottomPic} alt="" className="communityimg" />
        </Link>
        <CommunityContentDiv className="CommunityContentDiv">
          <h1>{t('community.title')}</h1>
          <p>{t('community.description')}</p>
          <Link to="/L22_Cuento" className="blocklink">
            <CommunityButton>{t('community.buttonText')}</CommunityButton>
          </Link>
        </CommunityContentDiv>
      </CommunityContainer>
      <CommunityContainer className="CommunityContainerMob">
        <Link to="/L22_Cuento">
          <img src={BottomPic} alt="" className="communityimg" />
        </Link>
        <CommunityContentDiv className="CommunityContentDiv">
          <h1>{t('community.title')}</h1>
          <p>{t('community.description')}</p>
          <Link to="/L22_Cuento" className="blocklink">
            <CommunityButton>{t('community.buttonText')}</CommunityButton>
          </Link>
        </CommunityContentDiv>
      </CommunityContainer>
    </React.Fragment>
  );
};

export default Community;
