import React, { useContext, useEffect } from 'react';
import './ThankyouMessage.css';
import i18n from './components/i18n';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from './Store';

const ThankyouMessage = () => {
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.userInfo && !state.userInfo?.isActivated) {
      navigate(`/${currentLanguage}/Login`);
    }
  }, [navigate, state.userInfo]);

  return (
    <div className="thankyoumessageContainer">
      <div className="thankyoucard">
        <div className="thankyoucard-content">
          <div className="thankyoucard-title">{t('ThankyouMessage.title')}</div>
          <p className="thankyoucard-description">
            {t('ThankyouMessage.text')}
          </p>
          <Link to={`/${currentLanguage}`} className="thankyoucard-button">
            {t('ThankyouMessage.button')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankyouMessage;
