import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SignupButton,
  SignupContainer,
  SignupFieldDiv,
  SignupWrapper,
  Signupbuttondiv,
} from './Signup.styles';
import SingupImage from '../../assets/Images/Hero/HeroImage.png';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import { message, Input, Spin } from 'antd';
import { Store } from '../../Store';
import axios from 'axios';
import i18n from '../i18n';

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // useNavigate hook for navigation
  const { search } = useLocation();

  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/Login';

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(''); // New state for name field
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const setEmailWithCapital = (email) => {
    const capitalizedEmail = email.charAt(0).toUpperCase() + email.slice(1);
    setEmail(capitalizedEmail);
  };
  const currentLanguage = i18n.language; // get the current language

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const handleSignup = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (password !== confirmPassword) {
      message.error('Passwords do not match');
      return;
    }
    try {
      const { data } = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/signup',
        {
          name,
          email,
          password,
        }
      );
      message.success(data.message);

      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      const expiresIn = 2 * 24 * 60 * 60 * 1000;
      const expirationTime = new Date().getTime() + expiresIn;
      localStorage.setItem('token', data.token);
      localStorage.setItem('tokenExpiration', expirationTime);
      navigate(`/${currentLanguage}/activate-account`);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        // Extract the error message from the response data
        message.error(err.response.data.message);
      } else {
        // If no specific error message is available, show a generic error
        message.error('An error occurred while signing up.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isActivated) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <SignupContainer className="SignupContainerMob">
      <SignupWrapper className="SignupWrapperMob">
        <div>
          <img src={SingupImage} alt="" className="signupimg" />
        </div>
        <SignupFieldDiv className="InputFieldBlockMob">
          <h1>{t('Register.logintitle')}</h1>
          <div>
            <label>{t('Register.yourName')}</label>
            <Input
              type="text"
              className="InputFieldMob"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%', height: '55px' }}
            />
            <label>{t('Register.yourEmail')}</label>
            <Input
              type="text"
              className="InputFieldMob"
              value={email}
              onChange={(e) => setEmailWithCapital(e.target.value)}
              style={{ width: '100%', height: '55px' }}
            />
            <label className="label12">{t('Register.setPassword')}</label>
            <Input.Password
              type="password"
              className="InputFieldMob"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: '100%' }}
            />
            <label className="label12">{t('Register.confirmPassword')}</label>
            <Input.Password
              type="password"
              className="InputFieldMob"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ width: '100%' }}
            />
          </div>
          <Signupbuttondiv>
            <SignupButton
              type="primary"
              onClick={handleSignup}
              style={{ marginRight: '8px' }}
            >
              {loading ? (
                <Spin className="ant-spin-nested-loading " />
              ) : (
                t('Register.loginButton')
              )}
            </SignupButton>
            <Link className="LoginLink" to={`/Login?redirect=${redirect}`}>
              {t('Register.ForgotPassword')}
            </Link>
          </Signupbuttondiv>
        </SignupFieldDiv>
      </SignupWrapper>
    </SignupContainer>
  );
};

export default Signup;
