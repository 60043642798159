import React, { useContext, useEffect, useReducer } from 'react';
import { Store } from '../Store';
import { getError } from '../UsefulFunctions';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Card } from 'antd';
import Chart from 'react-google-charts';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const Dashboard = () => {
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          'https://elescritor-io-22.onrender.com/api/orders/summary',
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [userInfo]);

  return (
    <div className="admin-container">
      <h1>Dashboard</h1>
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <Row className="custom-row">
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card className="custom-card">
                <Card.Meta
                  title={
                    summary.users && summary.users[0]
                      ? summary.users[0].numUsers
                      : 0
                  }
                  description="Users"
                />
              </Card>
            </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Card className="custom-card">
                  <Card.Meta
                    title={
                      summary.orders && summary.orders.length > 0
                        ? summary.orders[0].numOrders
                        : '0'
                    }
                    description="Letters Purchased"
                  />
                </Card>
              </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Card className="custom-card">
                <Card.Meta
                  title={
                    summary.orders &&
                    summary.orders.length > 0 &&
                    summary.orders[0].totalSales
                      ? `$${summary.orders[0].totalSales.toFixed(2)}`
                      : summary.orders && summary.orders.length === 0
                      ? '$0'
                      : 0 // Added to handle the case when summary.orders is undefined
                  }
                  description="Earnings"
                />
              </Card>
            </Col>
          </Row>
          <div className="my-3 sale-container">
            <h2>Sales</h2>
            {summary.dailyOrders.length === 0 ? (
              <h6>No Sale</h6>
            ) : (
              <Chart
                width="100%"
                height="400px"
                chartType="AreaChart"
                loader={<div>Loading Chart...</div>}
                data={[
                  ['Date', 'Sales'],
                  ...summary.dailyOrders.map((x) => [x._id, x.sales]),
                ]}
              ></Chart>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
