import React, { useEffect, useState } from 'react';

import { PremiumSelectBlock } from '../PresentationLetter/PresentationLetter.styles';
import { AboutusContainer, AboutusParagraph } from './AboutUs.styles';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the component when it mounts
  }, []);
  return (
    <AboutusContainer>
      <PremiumSelectBlock className="Nosotros">
        <h2>
          <span>{t('Aboutus.title')} </span>
        </h2>
      </PremiumSelectBlock>
      <AboutusParagraph>
        <p>{t('Aboutus.para1')}</p>
        <br />
        <br />
        <p>{t('Aboutus.para2')}</p> <br />
      </AboutusParagraph>
    </AboutusContainer>
  );
};

export default AboutUs;
