import styled from 'styled-components';

export const SignupContainer = styled.div`
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignupWrapper = styled.div`
  width: 80vw;
  height: 588px;
  padding: 15px;
  background: white;
  display: flex;
  margin-top: 150px;
  margin-bottom: 150px;
  gap: 40px;
`;

export const SignupFieldDiv = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #388e3b;
  }
  div {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  input {
    margin-top: 5px;
    width: 351px;
    height: 40px;
    border: 1px solid #000000;
    outline: none;
    color: #000000;
  }
`;

export const Signupbuttondiv = styled.span`
  display: flex;
  margin-top: 40px;
  gap: 20px;
`;
export const SignupButton = styled.button`
  width: 138px;
  height: 42px;
  background: #4caf4f;
  color: white;
  border: none;
  outline: none;
  border-radius: 6px;

  //styleName: Label / Medium Label;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
`;
export const FreeSignupButton = styled.button`
  width: 150px;
  height: 42px;
  background: #4caf4f;
  color: white;
  border: none;
  outline: none;
  border-radius: 6px;
  margin-top: 10px;
  //styleName: Label / Medium Label;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
`;
