import styled from 'styled-components';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-family: Inter;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
`;

export const ContactParagraph = styled.div`
  width: 80vw;
  margin-top: 5px;
  display: flex;
  flex-direction: column;

  p {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
  h4 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
`;

export const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
export const ContactInputFieldBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 60vw;
  .inputlabel {
    margin-left: 5px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
export const ContactInputField = styled.input`
  margin-top: 10px;
  width: 502px;
  height: 40px;
  border: 1px solid #000000;
  outline: none;
  padding-left: 5px;
`;
export const StyledContactInputField = styled.input`
  margin-top: 10px;
  width: 60vw;
  height: 40px;
  border: 1px solid #000000;
  outline: none;
  padding-left: 5px;
`;
export const ContactFullWidthInputBox = styled.textarea`
  margin-top: 10px;
  width: 60vw;
  height: 196px;
  border: 1px solid #000000;
  outline: none;
  padding-left: 5px;
  padding-top: 10px;
`;
export const ContactSubmitButton = styled.button`
  width: 173px;
  height: 52px;
  margin-top: 15px;
  padding: 14px 32px 14px 32px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #4caf4f;
  color: white;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
