import { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  letterCart: {
    paymentMethod: localStorage.getItem('paymentMethod')
      ? localStorage.getItem('paymentMethod')
      : '',
    cartLetters: localStorage.getItem('cartLetters')
      ? JSON.parse(localStorage.getItem('cartLetters'))
      : [],
  },
  // currentLanguage: localStorage.getItem('language') || 'es',
};
function reducer(state, action) {
  switch (action.type) {
    case 'CART_ADD_ITEM':
      const newItem = action.payload;
      const userId = state.userInfo ? state.userInfo._id : null;
      const userCartLetters = state.letterCart[userId] || [];
      const existingItemIndex = userCartLetters.findIndex(
        (item) => item.letterName === newItem.letterName
      );
      let updatedUserCartLetters = [];
      if (existingItemIndex !== -1) {
        updatedUserCartLetters = userCartLetters.map((item, index) =>
          index === existingItemIndex
            ? { ...item, letterPrice: newItem.letterPrice }
            : item
        );
      } else {
        updatedUserCartLetters = [...userCartLetters, newItem];
      }
      const updatedLetterCart = {
        ...state.letterCart,
        [userId]: updatedUserCartLetters,
      };
      localStorage.setItem('cartLetters', JSON.stringify(updatedLetterCart));
      return {
        ...state,
        letterCart: updatedLetterCart,
      };

    case 'CART_CLEAR':
      const userIdClear = state.userInfo ? state.userInfo._id : null; // Compute userId here
      localStorage.removeItem('cartLetters');
      return {
        ...state,
        letterCart: { ...state.letterCart, [userIdClear]: [] },
      };

    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload };
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
        letterCart: {
          cartLetters: [],
          paymentMethod: '',
        },
      };
    case 'SAVE_PAYMENT_METHOD':
      return {
        ...state,
        letterCart: { ...state.letterCart, paymentMethod: action.payload },
      };
    case 'CHANGE_LANGUAGE':
      return { ...state, currentLanguage: action.payload };

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children} </Store.Provider>;
}
