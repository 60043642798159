import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaqContainer, FaqParagraph } from './FAQ.styles';
import { PremiumSelectBlock } from '../PresentationLetter/PresentationLetter.styles';

const FAQ = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the component when it mounts
  }, []);
  return (
    <FaqContainer>
      <PremiumSelectBlock className="Nosotros">
        <h2>
          <span>{t('faq.title')}</span>
        </h2>
      </PremiumSelectBlock>
      <FaqParagraph>
        {t('faq.questions', { returnObjects: true }).map((item, index) => (
          <div key={index}>
            <p>
              <strong>{item.question}</strong> <br />
            </p>
            <p>{item.answer}</p> <br />
          </div>
        ))}
      </FaqParagraph>
    </FaqContainer>
  );
};

export default FAQ;
