import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import {
  HeroButton,
  HeroContainer,
  HeroContainerMob,
  HeroHeader,
  HeroHeaderMob,
  HeroImage,
  HeroImageMob,
  HeroImageWrapper,
  HeroPara,
  HeroTitle,
  HeroTitle2,
  HeroTitle2Mob,
  HeroTitleMob,
} from './Hero.styles';
import Heroimg from '../../assets/Images/Hero/HeroImage.png';
import { Link } from 'react-router-dom';
import i18n from '../i18n';

const Hero = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language; // get the current language

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
    <React.Fragment>
      <HeroContainer data-aos="fade-right" className="heroweb">
        <HeroHeader>
          <HeroTitle>
            {t('hero.title')} &nbsp; <br />
            <HeroTitle2>{t('hero.subtitle')}</HeroTitle2>
          </HeroTitle>
          <HeroPara>{t('hero.description')}</HeroPara>
          <HeroButton>{t('hero.button')}</HeroButton>
        </HeroHeader>
        <HeroImageWrapper data-aos="fade-left">
          <HeroImage src={Heroimg} alt="HeroImg" />
        </HeroImageWrapper>
      </HeroContainer>
      <HeroContainerMob data-aos="fade-right" className="heromob">
        <HeroImageWrapper data-aos="fade-left">
          <HeroImageMob src={Heroimg} alt="HeroImg" />
        </HeroImageWrapper>
        <HeroHeaderMob className="heroheadermob">
          <HeroTitleMob className="herotitlemob">
            {t('hero.title')} &nbsp; <br />
            <HeroTitle2Mob className="herotitle2mob">
              {t('hero.subtitle')}
            </HeroTitle2Mob>
          </HeroTitleMob>
          <HeroPara className="heroparamob">{t('hero.description')}</HeroPara>

          <HeroButton>
            <Link to={`${currentLanguage}/Signup`}>{t('hero.button')} </Link>
          </HeroButton>
        </HeroHeaderMob>
      </HeroContainerMob>
    </React.Fragment>
  );
};

export default Hero;
