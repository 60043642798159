import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Spin, Input } from 'antd';
import { toast } from 'react-toastify';

import {
  LoginButton,
  LoginContainer,
  LoginFieldDiv,
  LoginWrapper,
  Loginbuttondiv,
} from './Login.styles';
import LoginImage from '../../assets/Images/Hero/HeroImage.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../../Store';
import i18n from '../i18n';

const Login = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language; // get the current language

  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl || '/'; // Default redirect to home if not specified

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const setEmailWithCapital = (email) => {
    const capitalizedEmail = email.charAt(0).toUpperCase() + email.slice(1);
    setEmail(capitalizedEmail);
  };

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/signin',
        {
          email,
          password,
        }
      );

      if (!data.isAdmin && !data.isActivated) {
        message.error(
          'Account not activated. Please activate your account before logging in.'
        );
        setLoading(false);
        return;
      }

      // Proceed with login for admin or activated users
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      // Set token and its expiration time in local storage
      const expiresIn = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
      const expirationTime = new Date().getTime() + expiresIn;
      localStorage.setItem('token', data.token);
      localStorage.setItem('tokenExpiration', expirationTime);
      navigate(redirect); // Redirect to the specified route
    } catch (err) {
      // Handle error based on HTTP status code
      if (err.response && err.response.status === 401) {
        // Unauthorized - either incorrect password or unactivated account
        if (err.response.data.message === 'Account not activated.') {
          // Account not activated
          message.error(
            'Account not activated. Please activate your account before logging in.'
          );
        } else {
          // Incorrect password
          message.error('Incorrect Password');
        }
      } else {
        // Other errors
        message.error('An error occurred while logging in.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isActivated) {
      navigate('/');
    }
  }, [navigate, redirect, userInfo]);

  return (
    <LoginContainer className="SignupContainerMob">
      <LoginWrapper className="SignupWrapperMob">
        <div>
          <img src={LoginImage} alt="" className="signupimg" />
        </div>
        <LoginFieldDiv className="InputFieldBlockMob">
          <h1>{t('Login.logintitle')}</h1>
          <div>
            <label>{t('Login.yourEmail')}</label>
            <Input
              className="InputFieldMobss"
              style={{
                width: '100%',
                border: '1px solid #d9d9d9',
              }}
              type="text"
              value={email}
              onChange={(e) => setEmailWithCapital(e.target.value)}
            />
            <label className="label12">{t('Login.yourPassword')}</label>
            <Input.Password
              className="InputFieldMobss"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Loginbuttondiv>
            <LoginButton onClick={handleLogin} className="logbtn">
              {loading ? (
                <Spin className="ant-spin-nested-loading " />
              ) : (
                t('Login.loginButton')
              )}
            </LoginButton>
            <Link
              className="LoginLink"
              to={`/${currentLanguage}/forget-password`}
            >
              {t('Login.ForgotPassword')}
            </Link>
          </Loginbuttondiv>
        </LoginFieldDiv>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
