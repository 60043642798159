import React, { useContext, useEffect, useReducer } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import axios from 'axios';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import paypalbutton from './PaymentMethodScreen/paypalbutton.png';
import {
  StripeButton,
  SubmitButton,
} from './PresentationLetter/PresentationLetter.styles';
import './OrderScreen.css';
import paycards from './PaymentMethodScreen/paycards.jpg';

import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };
    default:
      return state;
  }
}
const stripePromise = loadStripe(
  'pk_live_51P6WvXRsiA3oHxqTOVAZlOL17ej4SVmza95lBFf1ad9UspMmLae0JJzReXIdX7Vy8L69TnAtVHYDbcpGiYjtpccW00NgYkgwJP'
);

const OrderScreen = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { t } = useTranslation(); // Initializing the useTranslation hook
  const currentLanguage = i18next.language; // get the current language

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();

  const [{ loading, error, order, successPay, loadingPay }, dispatch] =
    useReducer(reducer, {
      loading: true,
      order: {},
      error: '',
      successPay: false,
      loadingPay: false,
    });
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.letterPrice },

            application_context: {
              shipping_preference: 'NO_SHIPPING',
              user_action: 'PAY_NOW',
              billing_address: 'NO',
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }
  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        dispatch({ type: 'PAY_REQUEST' });
        const { data } = await axios.put(
          `/api/orders/${order._id}/pay`,
          details,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        message.success('Order is paid');
        await axios.put(
          `/api/users/${userInfo._id}/setFormSubmittedFalse`,
          { letterName: order.letterName },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        navigate(`/${order.letterName}`);
        // navigate(`/${order.letterName}`);
      } catch (err) {
        dispatch({ type: 'PAY_FAIL', payload: err.message });
        message.error(err);
      }
    });
  }
  function onError(err) {
    message.error(err.message);
  }

  const handleStripePayment = async () => {
    try {
      const stripe = await stripePromise;

      const response = await axios.post(
        '/api/orders/create-checkout-session',
        { orderId, language: currentLanguage },
        { headers: { Authorization: `Bearer ${userInfo.token}` } }
      );

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        message.error(result.error.message);
      }
    } catch (error) {
      message.error('Failed to initiate Stripe payment');
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };

    if (!userInfo) {
      navigate(`/${currentLanguage}/Login`);
    }

    if (!order._id || successPay || (order._id && order._id !== orderId)) {
      fetchOrder();
      if (successPay) {
        dispatch({ type: 'PAY_RESET' });
      }
    } else {
      const loadPaypalScript = async () => {
        const { data: clientId } = await axios.get('/api/keys/paypal', {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': clientId,
            currency: 'USD',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      loadPaypalScript();
    }
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      message.success('Order placed! You will receive an email confirmation.');
      navigate(`/${currentLanguage}/${order.letterName}`);
    }

    if (query.get('canceled')) {
      message.warning('Order canceled');
    }
  }, [
    order,
    userInfo,
    orderId,
    navigate,
    paypalDispatch,
    successPay,
    currentLanguage,
  ]);

  return loading ? (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className="PlaceOrderContainer">
      <div className="card">
        <div className="card-content">
          <p className="card-title">{t('orderscreen.title')}</p>
          <div className="itemsdetails">
            <h2 className="ordername">
              {t('orderscreen.orderId')}
              <span className="orderid">{orderId}</span>{' '}
            </h2>

            <div>
              <h2 className="ordername">
                <strong>
                  {t('orderscreen.userName')}{' '}
                  <span className="orderid">{userInfo.name}</span>
                </strong>
              </h2>
            </div>

            <div className="payments">
              <h2 className="order12">{t('orderscreen.items')}</h2>
              <h4>
                <strong className="strongprice">
                  {t('orderscreen.LetterName')}
                </strong>{' '}
                <span className="orderid"> {order?.letterName}</span>
              </h4>
              <h4>
                <strong className="strongprice">
                  {t('orderscreen.LetterPrice')}
                </strong>{' '}
                <span className="orderid">$ {order?.letterPrice}</span>
              </h4>
            </div>
            {/* {order.isPaid ? (
              <div>
                <h4 className="paid">
                  <strong className="paidat">Paid At:</strong>{' '}
                  {new Date(order.paidAt).toUTCString()}
                </h4>
              </div>
            ) : (
              <div className="notpaid"> Not Paid</div>
            )} */}
          </div>
          <p className="card-title">{t('orderscreen.clicktobuy')} </p>
          {!order.isPaid && (
            <div>
              {/* Conditionally render PayPal or Stripe buttons */}
              {order.paymentMethod === 'PayPal' && (
                <>
                  {isPending ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ) : (
                    <PayPalButtons
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  )}
                </>
              )}
              {order.paymentMethod === 'Stripe' && !userInfo.isAdmin ? (
                <>
                  <img
                    className="paypalbtn"
                    src={paycards}
                    alt="Stripe Button"
                    onClick={handleStripePayment}
                  />
                </>
              ) : (
                ''
              )}
              {loadingPay && (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderScreen;
