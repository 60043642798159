import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ContactContainer,
  ContactFormWrapper,
  ContactFullWidthInputBox,
  ContactInputField,
  ContactInputFieldBlock,
  ContactParagraph,
  ContactSubmitButton,
} from './Contact.styles';
import { PremiumSelectBlock } from '../PresentationLetter/PresentationLetter.styles';
import { message } from 'antd';
import Loader from '../Loader/Loader';
const Contact = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [loading, setLoading] = useState(false); // Add a new piece of state to track loading status

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/contactus',
        formData
      );
      console.log(response.data);
      message.success(t('contactusform.successmessage'));
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false); // Set loading back to false when the request is done
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContactContainer>
      <PremiumSelectBlock className="Nosotros">
        <h2>
          <span>{t('contact.title')}</span>
        </h2>
      </PremiumSelectBlock>
      <ContactParagraph>
        <p className="tcpara">
          <strong>{t('contact.strongtext')}</strong>
          {t('contact.intro')}
        </p>
        <p className="tcpara">
          <strong>{t('contact.form.title')}</strong>
          <br />
          {t('contact.form.instructions')}
        </p>
      </ContactParagraph>
      <form onSubmit={handleSubmit}>
        <ContactFormWrapper className="ContactFormWrappermob">
          <ContactInputFieldBlock className="ContactInputFieldBlock">
            <label className="Tucorreo">{t('contact.form.fields.name')}</label>
            <ContactInputField
              type="text"
              className="ContactInputField"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </ContactInputFieldBlock>
          <ContactInputFieldBlock>
            <label className="Tucorreo">{t('contact.form.fields.email')}</label>
            <ContactInputField
              type="text"
              className="ContactInputField"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </ContactInputFieldBlock>
          <ContactInputFieldBlock>
            <label className="Tucorreo">
              {t('contact.form.fields.subject')}
            </label>
            <ContactInputField
              type="text"
              className="ContactInputField"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </ContactInputFieldBlock>
          <ContactInputFieldBlock>
            <label className="Tucorreo">
              {t('contact.form.fields.message')}
            </label>
            <ContactFullWidthInputBox
              type="text"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </ContactInputFieldBlock>
          <ContactSubmitButton type="submit">
            {t('contact.form.submitButton')}
          </ContactSubmitButton>
          {loading && <Loader />}
        </ContactFormWrapper>
      </form>

      <ContactParagraph>
        <p className="tcpara">
          <strong>{t('contact.directContact.title')}</strong>
          <br />
          {t('contact.directContact.instructions')}{' '}
          <a href={`mailto:${t('contact.directContact.email')}`}>
            {t('contact.directContact.email')}
          </a>
        </p>
        <p className="tcpara">{t('contact.directContact.responseTime')}</p>
      </ContactParagraph>
    </ContactContainer>
  );
};

export default Contact;
