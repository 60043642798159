import styled from 'styled-components';

export const CommunityContainer = styled.div`
  display: flex;
  margin-top: 60px;
  margin-bottom: 160px;
`;

export const CommunityContentDiv = styled.div`
  border: 1px solid #4caf4f;
  width: 100%;
  height: 400px;
  margin-left: -30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: var(--Neutral-D_Grey, #4d4d4d);
    text-align: center;

    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  p {
    color: var(--Neutral-L_Grey, #89939e);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width: 84%;
  }
`;

export const CommunityButton = styled.div`
  width: 144px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  //styleName: Label / Medium Label;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  background: #4caf4f;
  color: white;
`;
