import styled from 'styled-components';

export const LoginContainer = styled.div`
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  width: 80vw;
  height: 588px;
  padding: 15px;
  background: white;
  display: flex;
  margin-top: 150px;
  margin-bottom: 150px;
  gap: 40px;
`;

export const LoginFieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #388e3b;
  }
  div {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
`;

export const Loginbuttondiv = styled.span`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 20px;
`;
export const LoginButton = styled.button`
  width: 93px;
  height: 42px;
  background: #4caf4f;
  color: white;
  border: none;
  outline: none;
  border-radius: 6px;

  //styleName: Label / Medium Label;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
`;
