import styled from "styled-components";

export const AboutusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AboutusParagraph = styled.div`
  width: 80vw;
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  p {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
`;
