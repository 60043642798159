import styled from 'styled-components';

export const PresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
`;

export const ContentDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4d4d;
  }
  span {
    //styleName: Heading/Headline 2;
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #4caf4f;
  }
  p {
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    font-weight: 400;
    text-align: left;
    color: #18191f;
  }
`;

export const ContentDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;

  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
  }

  span {
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const ContentDivBlock = styled.div`
  width: 299px;
  height: 228px;
  background: #f5f7fa;
  padding: 24px 32px 24px 32px;
  border-radius: 8px;
  gap: 8px;
  box-shadow: 0px 2px 4px 0px #abbed133;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  h3 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #4d4d4d;
  }

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const FormWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px;
  gap: 35px;

  h3 {
    //styleName: Heading/Headline 2;
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
`;

export const InputFieldDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InputFieldBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .inputlabel {
    margin-left: 5px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
export const InputField = styled.input`
  margin-top: 10px;
  width: 502px;
  height: 40px;
  border: 1px solid #000000;
  outline: none;
  padding-left: 5px;
`;
export const StyledInputBox = styled.textarea`
  margin-top: 10px;
  padding-top: 15px;
  width: 502px;
  height: 154px;
  border: 1px solid #000000;
  outline: none;
  padding-left: 10px;
`;
export const FullWidthInputBox = styled.textarea`
  margin-top: 10px;
  width: 92%;
  height: 154px;
  border: 1px solid #000000;
  outline: none;
  padding-top: 15px;
  padding-left: 5px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1 {
    width: 70vw;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
`;

export const SubmitButton = styled.button`
  width: 173px;
  height: 52px;

  padding: 14px 32px 14px 32px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #4caf4f;
  color: white;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const SubmitButtonPlace = styled.button`
  width: 173px;
  height: 52px;

  padding: 14px 32px 14px 32px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #ecb839;
  color: black;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const SubmitButtonForget = styled.button`
  width: 100%;
  height: 52px;
  padding: 14px 32px 14px 32px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #4caf4f;
  color: white;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const StripeButton = styled.button`
  width: 200px;
  height: 52px;

  /* padding: 14px 32px 14px 32px; */
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #635bff;
  color: white;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const ProceedCheckout = styled.button`
  width: 100%;
  height: 52px;
  margin-top: 15px;
  padding: 14px 32px 14px 32px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #ecb839;
  color: black;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const EditSubmitButton = styled.button`
  width: 220px;
  height: 52px;

  padding: 14px 32px 14px 32px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #4caf4f;
  color: white;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const PlaceOrderButton = styled.button`
  width: 320px;
  height: 52px;
  margin-top: 20px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  background: #4caf4f;
  color: white;
  cursor: pointer;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

export const TextField = styled.textarea`
  width: 92%;
  height: 144px;
  border: 1px solid #000000;
  outline: none;
  margin-top: 5px;
  padding-left: 10px;
  padding-top: 15px;
`;
export const FullWidthInput = styled.input`
  width: 92%;
  height: 40px;
  border: 1px solid #000000;
  outline: none;
  margin-top: 5px;
`;

export const TextField2 = styled.textarea`
  width: 92%;
  height: 76px;
  border: 1px solid #000000;
  outline: none;
  margin-top: 5px;
  padding-top: 15px;
  padding-left: 10px;
`;

export const RenunContentDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* gap: 150px; */
  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4d4d;
  }
  span {
    //styleName: Heading/Headline 2;
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #4caf4f;
  }

  p {
    width: 45vw;
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    font-weight: 400;
    text-align: left;
    color: #18191f;
  }
`;

export const PremiumSelectBlock = styled.div`
  height: 38px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #43a046;
  }
  span {
    color: #4d4d4d;
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const PurchaseButton = styled.div`
  width: 13vw;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  //styleName: Body/Medium/Body 2;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  background: #4caf4f;
`;

export const LanguageSelectDiv = styled.div`
  width: 84.5%;
  background: #e8f5e9;
  height: 120px;

  p {
    width: 100%;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 0px !important;
  }
`;
