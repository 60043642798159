import React, { useEffect } from 'react';
import Hero from '../HeroSection/Hero';
import Achievements from '../Achievements/Achievements';
import FreeLetters from '../FreeLetters/FreeLetters';
import PremiumLetter from '../PremiumLetters/PremiumLetter';
import Community from '../Community/Community';
import Footer from '../Footer/Footer';

const Home = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Hero />
      <Achievements />
      <FreeLetters />
      <PremiumLetter />
      <Community />
    </React.Fragment>
  );
};

export default Home;
