import React, { useContext, useEffect, useReducer } from 'react';
import { Store } from '../../Store';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons'; // Import LoadingOutlined
import paypalbutton from '../PaymentMethodScreen/paypalbutton.png'; // Import the PayPal button image
import paycards from '../PaymentMethodScreen/paycards.jpg'; // Import the Stripe button image
import {
  ProceedCheckout,
  SubmitButton,
  SubmitButtonPlace,
} from '../PresentationLetter/PresentationLetter.styles';
import { Spin, message } from 'antd';
import '../OrderScreen.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../i18n';
const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};
const PlaceOrder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initializing the useTranslation hook

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { letterCart, userInfo } = state;
  const currentLanguage = i18n.language; // get the current language

  useEffect(() => {
    if (!userInfo) {
      navigate(`/${currentLanguage}/Login`);
    }
  }, [currentLanguage, navigate, userInfo]);

  useEffect(() => {
    if (!letterCart.paymentMethod) {
      navigate(`/${currentLanguage}/SelectPaymentMethod`);
    }
  }, [letterCart, navigate]);

  const getLetterDetails = () => {
    // Retrieve letter details from local storage
    const storedDetails = localStorage.getItem('selectedLetter');

    // If letter details are found in local storage, return them
    if (storedDetails) {
      const { letterName, letterPrice } = JSON.parse(storedDetails);
      return { letterName, letterPrice };
    }

    const userId = state.userInfo ? state.userInfo._id : null;
    if (userId && letterCart[userId]?.length > 0) {
      const { letterName, letterPrice } = letterCart[userId][0];
      return { letterName, letterPrice };
    }

    return { letterName: 'No letter selected', letterPrice: 0 };
  };

  const { letterName, letterPrice } = getLetterDetails();
  const placeOrderHandler = async () => {
    try {
      dispatch({ type: 'CREATE_REQUEST' });
      const { letterName, letterPrice } = getLetterDetails(); // Retrieve letterName and letterPrice

      const { data } = await axios.post(
        'https://elescritor-io-22.onrender.com/api/orders',
        {
          paymentMethod: letterCart.paymentMethod,
          letterPrice: letterPrice,
          letterName: letterName,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      ctxDispatch({ type: 'CART_CLEAR' });
      dispatch({ type: 'CREATE_SUCCESS' });
      localStorage.removeItem('cartItems');
      navigate(`/order/${data.order._id}`);
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' });
      message.error(err.message); // Display the error message
    }
  };

  return (
    <div className="PlaceOrderContainer">
      <div className="card">
        <div className="card-content">
          <h1 className="card-title">{t('PlaceOrder.letterdetails')}</h1>
          <div className="itemsdetails">
            <div>
              <div className="placeflex">
                <h2>
                  <strong className="placepayment">
                    {t('PlaceOrder.paymentmethod')}
                  </strong>
                </h2>
                {letterCart.paymentMethod === 'Stripe' && (
                  <img
                    className="paypalbtnd"
                    src={paycards}
                    alt="Stripe Button"
                  />
                )}
                {letterCart.paymentMethod === 'PayPal' && (
                  <img
                    className="paypalbtnd"
                    src={paypalbutton}
                    alt="Stripe Button"
                  />
                )}
              </div>

              <Link to="/SelectPaymentMethod" className="EditMethod">
                {t('PlaceOrder.editmethod')}
              </Link>
            </div>
            <div>
              <h2 className="placeitems">{t('PlaceOrder.items')}</h2>
              <h2 className="spanplace1">
                {t('PlaceOrder.LetterName')}
                &nbsp; <span className="spanplace">{letterName}</span>
              </h2>
              <Link to="/" className="EditMethod">
                {t('PlaceOrder.EditLetter')}
              </Link>
              <h2 className="spanplace1">
                {t('PlaceOrder.LetterPrice')}
                <span className="spanplace"> ${letterPrice}</span>
              </h2>
            </div>
            <div className="plcorderbtn">
              <ProceedCheckout onClick={placeOrderHandler}>
                {t('PlaceOrder.ProceedCheckout')}
              </ProceedCheckout>
            </div>
            {loading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
