import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UsePolicyContainer, UsePolicyParagraph } from './UsePolicies.styles';
import { PremiumSelectBlock } from '../PresentationLetter/PresentationLetter.styles';

const UsePolicies = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <UsePolicyContainer>
      <PremiumSelectBlock className="Nosotros">
        <h2>
          <span>{t('usePolicies.title')}</span>
        </h2>
      </PremiumSelectBlock>
      <UsePolicyParagraph>
        <h6><strong>{t('usePolicies.lastUpdate')}</strong></h6>
        <p className="tcpara">{t('usePolicies.description')}</p>
        <br />
      </UsePolicyParagraph>
      {t('usePolicies.sections', { returnObjects: true }).map(
        (section, index) => (
          <UsePolicyParagraph key={index}>
            <h4>
              <strong>{section.title}</strong>
            </h4>
            <p className="tcpara">{section.content}</p>
            <br /> <br />
          </UsePolicyParagraph>
        )
      )}
    </UsePolicyContainer>
  );
};

export default UsePolicies;
