import styled from 'styled-components';

export const AchieveContainer = styled.div`
  padding: 64px 144px 64px 144px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e8f5e9;
`;

export const AchievementsHeader = styled.div`
  display: flex !important;
  flex-direction: column;
`;
export const AchievementsHeaderMob = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AchieveTitle = styled.h1`
  color: var(--Neutral-D_Grey, #4d4d4d);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 0px !important;
`;
export const AchieveTitleMob = styled.h1`
  color: var(--Neutral-D_Grey, #4d4d4d);
  font-family: Inter;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 0px !important;
  text-align: center;
`;
export const AchieveTitle2 = styled.span`
  font-family: Inter;
  font-size: 36px !important;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px !important;
  color: #4caf4f;
`;
export const AchieveTitle2Mob = styled.span`
  font-family: Inter;
  font-size: 28px !important;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px !important;
  color: #4caf4f;
  text-align: center;
`;
export const Achievepara = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
`;
export const AchieveparaMob = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
  text-align: center;
`;
export const AchieveImageWrapper = styled.div`
  display: flex;
  width: 50vw;
  margin-left: 20px;
`;
export const AchieveImage = styled.img``;
