import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TCContainer, TCParagraph } from './TermsAndConditions.styles';
import { PremiumSelectBlock } from '../PresentationLetter/PresentationLetter.styles';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the component when it mounts
  }, []);
  return (
    <TCContainer>
      <PremiumSelectBlock className="Nosotros">
        <h2>
          <span>{t('termsAndConditions.title')}</span>
        </h2>
      </PremiumSelectBlock>
      <TCParagraph>
        <h6>
          <strong>{t('termsAndConditions.lastUpdate')}</strong>
        </h6>
      </TCParagraph>
      {t('termsAndConditions.sections', { returnObjects: true }).map(
        (section, index) => (
          <TCParagraph key={index}>
            <h4>
              <strong>{section.title}</strong>
            </h4>
            <p className="tcpara">{section.content}</p>
            <br /> <br />
          </TCParagraph>
        )
      )}
    </TCContainer>
  );
};

export default TermsAndConditions;
