import React, { useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import axios from 'axios';
import { getError } from '../UsefulFunctions';
import { Spin, Table, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const UserListScreen = () => {
  const navigate = useNavigate();
  const [{ loading, error, users }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    users: [],
  });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/users`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [userInfo]);

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'IS ADMIN',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (isAdmin) => (isAdmin ? 'YES' : 'NO'),
    },
  ];

  // CSV data
  const csvData = users.map((user) => ({
    ID: user._id,
    NAME: user.name,
    EMAIL: user.email,
    'IS ADMIN': user.isAdmin ? 'YES' : 'NO',
  }));

  return (
    <React.Fragment>
      <div className="table-container">
        <h2>Users</h2>
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            <Table
              columns={columns}
              dataSource={users}
              rowKey="_id"
              className="custom-table"
            />
            <Button type="primary" style={{ marginTop: '10px' }}>
              <CSVLink data={csvData} filename={'users.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          </div>
        )}
      </div>
      <div className="table-containermob">
        <h2>Users</h2>
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={users}
                rowKey="_id"
                className="custom-table"
              />
            </div>
            <Button type="primary" style={{ marginTop: '10px' }}>
              <CSVLink data={csvData} filename={'users.csv'}>
                Download CSV
              </CSVLink>
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UserListScreen;
