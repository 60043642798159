import React, { useContext, useEffect, useState } from 'react';
import {
  PremiumBlock1,
  PremiumBlockContent,
  PremiumBlockDiv,
  PremiumButton,
  PremiumContainer,
} from './PremiumLetters.styles';
import PBlock1 from '../../assets/Images/PremiumLetters/PBlock1.png';
import PBlock2 from '../../assets/Images/PremiumLetters/PBlock2.png';
import PBlock3 from '../../assets/Images/PremiumLetters/PBlock3.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, Menu, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { SignupButton } from '../Signup/Signup.styles';
import { Store } from '../../Store';
import i18n from '../i18n';

const PremiumLetter = (props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const { state } = useContext(Store);
  const { userInfo } = state;
  const currentLanguage = i18n.language; // get the current language

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSignUp = () => {
    navigate(`/${currentLanguage}/Signup`);
    setIsModalVisible(false);
  };
  const handleClick = (letter) => {
    setSelectedLetter(letter);
    if (!userInfo?.isActivated) {
      setIsModalVisible(true);
    } else {
      navigate(`/${currentLanguage}/${letter}`);
    }
  };

  const handleMenuClick = (e, category) => {
    navigate('/');
  };
  const menuItemStyle = {
    border: '1px solid green',
    marginTop: '5px',
  };

  const menuPersonal = (
    <Menu>
      <Link to={`/${currentLanguage}/L11_Declaracion`}>
        <Menu.Item
          key="1"
          style={menuItemStyle}
          onClick={() => handleClick('DeclaracionAmorosa')}
        >
          {t('paidletterslist.loveletter')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L12_Nacimiento`}>
        <Menu.Item
          key="2"
          style={menuItemStyle}
          onClick={() => handleClick('NewBabyCongratulationsLetter')}
        >
          {t('paidletterslist.Felicitaciónpornuevo')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L13_Logro`}>
        <Menu.Item
          key="3"
          style={menuItemStyle}
          onClick={() => handleClick('CongratstoFriendorFamilyLetter')}
        >
          {t('paidletterslist.Cartadefelicitaciónaunamigo')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L14_Cumpleaños`}>
        <Menu.Item
          key="4"
          style={menuItemStyle}
          onClick={() => handleClick('BirthdayLetter')}
        >
          {t('paidletterslist.CartadeFelicitacióndecumpleaños')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L15_Animo`}>
        <Menu.Item
          key="5"
          style={menuItemStyle}
          onClick={() => handleClick('EncouragementLetter')}
        >
          {t('paidletterslist.Cartadeánimo')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L16_Boda`}>
        <Menu.Item
          key="6"
          style={menuItemStyle}
          onClick={() => handleClick('WeddingInviteLetter')}
        >
          {t('paidletterslist.CartaInvitaciónaboda')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L17_Disculpas`}>
        <Menu.Item
          key="7"
          style={menuItemStyle}
          onClick={() => handleClick('ApologizingLetter')}
        >
          {t('paidletterslist.Cartadeperdónaalguien')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L18_Amor`}>
        <Menu.Item
          key="8"
          style={menuItemStyle}
          onClick={() => handleClick('PartnerorChildrenLoveLetter')}
        >
          {t('paidletterslist.Cartadeamor')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L19_Despedida`}>
        <Menu.Item
          key="9"
          style={menuItemStyle}
          onClick={() => handleClick('GoodbyeLetter')}
        >
          {t('paidletterslist.Cartadedespedida')}
        </Menu.Item>
      </Link>
    </Menu>
  );

  const menuProfessional = (
    <Menu onClick={(e) => handleMenuClick(e, 'professional')}>
      <Link to={`/${currentLanguage}/L04_Renuncia`}>
        <Menu.Item
          key="1"
          style={menuItemStyle}
          onClick={() => handleClick('ResignationLetter')}
        >
          {t('paidletterslist.CartadeRenuncia')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L05_Critica`}>
        <Menu.Item
          key="2"
          style={menuItemStyle}
          onClick={() => handleClick('ConstructiveCriticismLetter')}
        >
          {t('paidletterslist.CartadeQueja')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L06_Tiempo`}>
        <Menu.Item
          key="3"
          style={menuItemStyle}
          onClick={() => handleClick('RequestforAdditionalTimeLetter')}
        >
          {t('paidletterslist.CartaSolicitando')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L07_ProntoPago`}>
        <Menu.Item
          key="4"
          style={menuItemStyle}
          onClick={() => handleClick('RequestPromptPaymentLetter')}
        >
          {t('paidletterslist.Solicitandopronto')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L09_Recomendacion`}>
        <Menu.Item
          key="5"
          style={menuItemStyle}
          onClick={() => handleClick('RecommendationLetter')}
        >
          {t('paidletterslist.Cartaderecomendación')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L08_Apreciacion`}>
        <Menu.Item
          key="6"
          style={menuItemStyle}
          onClick={() => handleClick('AppreciationLetter')}
        >
          {t('paidletterslist.CartadeApreciación')}
        </Menu.Item>
      </Link>
      <Link to={`/${currentLanguage}/L10_Agradecimiento`}>
        <Menu.Item
          key="7"
          style={menuItemStyle}
          onClick={() => handleClick('ThankstoClientorVendorLetter')}
        >
          {t('paidletterslist.Cartadeagradecimiento')}
        </Menu.Item>
      </Link>
    </Menu>
  );

  const menuCreative = (
    <Menu onClick={(e) => handleMenuClick(e, 'creative')}>
      <Link to={`/${currentLanguage}/L20_Futuro`}>
        <Menu.Item
          key="1"
          style={menuItemStyle}
          onClick={() => handleClick('FutureLetter')}
        >
          {t('paidletterslist.Cartaatuyodelfuturo')}
        </Menu.Item>
      </Link>

      <Link to={`/${currentLanguage}/L21_Universo`}>
        <Menu.Item
          key="2"
          style={menuItemStyle}
          onClick={() => handleClick('UniverseLetter')}
        >
          {t('paidletterslist.CartaalaTierra')}
        </Menu.Item>
      </Link>
    </Menu>
  );

  return (
    <React.Fragment>
      <PremiumContainer className="premlettercontainerweb">
        <h1 className="h1prem">
          {t('premiumLetters.title')} &nbsp;
          <span className="h1prem">{t('premiumLetters.subtitle')}</span>
        </h1>
      </PremiumContainer>
      <PremiumContainer className="premlettercontainermob">
        <h1 className="h1prem">
          {t('premiumLetters.title')} &nbsp;
          <span className="h1prem">{t('premiumLetters.subtitle')}</span>
        </h1>
      </PremiumContainer>
      <PremiumBlockDiv className="PremiumBlockDivmob">
        <PremiumBlock1 className="PremiumBlock1">
          <img src={PBlock1} alt="PBlock1" data-aos="fade-right" />
          <PremiumBlockContent
            data-aos="fade-left"
            className="PremiumBlockContentmob"
          >
            <h1 className="PremiumBlockh1">{t('premiumLetters.personal')}</h1>
            <p>{t('premiumLetters.personalDescription')}</p>
            <Dropdown overlay={menuPersonal} placement="bottomLeft" arrow>
              <PremiumButton>
                {t('premiumLetters.selectCategory')}
              </PremiumButton>
            </Dropdown>
          </PremiumBlockContent>
        </PremiumBlock1>
        <PremiumBlock1 className="PremiumBlock1">
          <img src={PBlock2} alt="PBlock2" data-aos="fade-right" />
          <PremiumBlockContent
            data-aos="fade-left"
            className="PremiumBlockContentmob"
          >
            <h1 className="PremiumBlockh1">
              {t('premiumLetters.professional')}
            </h1>
            <p>{t('premiumLetters.professionalDescription')}</p>
            <Dropdown overlay={menuProfessional} placement="bottomLeft" arrow>
              <PremiumButton>
                {t('premiumLetters.selectCategory')}
              </PremiumButton>
            </Dropdown>
          </PremiumBlockContent>
        </PremiumBlock1>
        <PremiumBlock1 className="PremiumBlock1">
          <img src={PBlock3} alt="PBlock3" data-aos="fade-right" />
          <PremiumBlockContent
            data-aos="fade-left"
            className="PremiumBlockContentmob"
          >
            <h1 className="PremiumBlockh1">{t('premiumLetters.creative')}</h1>
            <p>{t('premiumLetters.creativeDescription')}</p>
            <Dropdown overlay={menuCreative} placement="bottomLeft" arrow>
              <PremiumButton>
                {t('premiumLetters.selectCategory')}
              </PremiumButton>
            </Dropdown>
          </PremiumBlockContent>
        </PremiumBlock1>
      </PremiumBlockDiv>
      <Modal
        title="Sign Up to Generate Letter"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <p>Please sign up to generate the letter.</p>
        <Link to="/Signup">
          <SignupButton onClick={handleSignUp}>Sign up</SignupButton>
        </Link>
      </Modal>
    </React.Fragment>
  );
};

export default PremiumLetter;
