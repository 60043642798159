import styled from "styled-components";

export const PCContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-family: Inter;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
`;

export const PCParagraph = styled.div`
  width: 80vw;
  margin-top: 5px;
  display: flex;
  flex-direction: column;

  p {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
  h4 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #18191f;
  }
`;
