import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AchieveContainer,
  AchieveImage,
  AchieveImageWrapper,
  AchieveTitle,
  AchieveTitle2,
  AchieveTitle2Mob,
  AchieveTitleMob,
  AchievementsHeader,
  AchievementsHeaderMob,
  Achievepara,
  AchieveparaMob,
} from './achievements.styles';
import Counts from '../../assets/Images/Hero/Counts.svg';
import CountsEnglish from '../../assets/Images/Hero/CountsEnglish.png';
import countimage1 from '../../assets/Images/countimage1.png';
import countimage2 from '../../assets/Images/countimage2.png';
import countimage3 from '../../assets/Images/countimage3.png';
import countimage4 from '../../assets/Images/countimage4.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const Achievements = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  const [usersCount, setUsersCount] = useState(null);
  const [error, setError] = useState(null);

  const [displayCount, setDisplayCount] = useState(2000); // Start count from 2000

   useEffect(() => {
    const fetchUsersCount = async () => {
      try {
        const response = await axios.get(
          'https://elescritor-io-22.onrender.com/api/users/count'
        );
        const latestCount = response.data.count;
        setDisplayCount(2000 + latestCount); // Update displayCount with latest count
      } catch (error) {
        console.error('Failed to fetch user count:', error);
      }
    };

    const interval = setInterval(fetchUsersCount, 60000); // Fetch every 60 seconds
    fetchUsersCount(); // Initial fetch
    return () => clearInterval(interval);
  }, []);
  const [lettersCount, setLettersCount] = useState(1934); // Add this line

  useEffect(() => {
    const fetchLettersCount = async () => {
      try {
        const response = await axios.get(
          'https://elescritor-io-22.onrender.com/api/users/lettersGenerated' // Replace with your actual API endpoint
        );
        setLettersCount(response.data.count);
      } catch (error) {
        console.error('Failed to fetch letters count:', error);
      }
    };

    fetchLettersCount();
  }, []);
  const [wordsTyped, setWordsTyped] = useState(0);
  const [paymentsReceived, setPaymentsReceived] = useState(1920);

  useEffect(() => {
    setWordsTyped(lettersCount * 200);
  }, [lettersCount]);

  useEffect(() => {
    setPaymentsReceived(1900 + Math.floor((displayCount - 2000) / 2));
  }, [displayCount]);

  return (
    <React.Fragment>
      <AchieveContainer className="achieveweb">
        <AchievementsHeader data-aos="fade-right">
          <AchieveTitle>
            {t('achievements.title')} <br />
            <AchieveTitle2>{t('achievements.subtitle')}</AchieveTitle2>
          </AchieveTitle>
          <Achievepara>{t('achievements.description')}</Achievepara>
        </AchievementsHeader>
        <div className="achievementsdata">
          <div className="countblock1">
            <img src={countimage1} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>{displayCount}</h1> {/*Count Start it after 2000*/}
              <p>{t('achievements.members')}</p>
            </div>
          </div>
          <div className="countblock1">
            <img src={countimage2} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>{lettersCount}</h1>
              <p>{t('achievements.lettersgenerated')}</p>
            </div>
          </div>
          <div className="countblock1">
            <img src={countimage3} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>{wordsTyped}</h1>
              <p>{t('achievements.wordtyped')}</p>
            </div>
          </div>
          <div className="countblock1">
            <img src={countimage4} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>{paymentsReceived}</h1>
              <p>{t('achievements.paymentRecieved')}</p>
            </div>
          </div>
        </div>
        {/* <AchieveImageWrapper data-aos="fade-left">
        <AchieveImage src={isEnglish ? CountsEnglish : Counts} alt="" />
      </AchieveImageWrapper> */}
      </AchieveContainer>
      <AchieveContainer className="achievemob">
        <AchievementsHeaderMob data-aos="fade-right">
          <AchieveTitleMob>
            {t('achievements.title')} <br />
            <AchieveTitle2Mob>{t('achievements.subtitle')}</AchieveTitle2Mob>
          </AchieveTitleMob>
          <AchieveparaMob>{t('achievements.description')}</AchieveparaMob>
        </AchievementsHeaderMob>
        <div className="achievementsdata">
          <div className="countblock1">
            <img src={countimage1} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>{displayCount}</h1> {/*Count Start it after 2000*/}
              <p>{t('achievements.members')}</p>
            </div>
          </div>
          <div className="countblock1">
            <img src={countimage2} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>{lettersCount}</h1>
              <p>{t('achievements.lettersgenerated')}</p>
            </div>
          </div>
          <div className="countblock1">
            <img src={countimage3} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>2,882,500</h1>
              <p>{t('achievements.wordtyped')}</p>
            </div>
          </div>
          <div className="countblock1">
            <img src={countimage4} alt="" className="countimage1" />
            <div className="countdata1">
              <h1>5,000</h1>
              <p>{t('achievements.paymentRecieved')}</p>
            </div>
          </div>
        </div>
        {/* <AchieveImageWrapper data-aos="fade-left">
        <AchieveImage src={isEnglish ? CountsEnglish : Counts} alt="" />
      </AchieveImageWrapper> */}
      </AchieveContainer>
    </React.Fragment>
  );
};

export default Achievements;
