import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Spin, Input } from 'antd';
import {
  LoginContainer,
  LoginFieldDiv,
  LoginWrapper,
  Loginbuttondiv,
} from './Login/Login.styles';
import { LoadingOutlined } from '@ant-design/icons';

import LoginImage from '../assets/Images/Hero/HeroImage.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../UsefulFunctions';
import { SubmitButtonForget } from './PresentationLetter/PresentationLetter.styles';
import Loader from './Loader/Loader';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    if (userInfo || !token) {
      navigate('/Login');
    }
  }, [navigate, token, userInfo]);

  const SubmitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      message.error("Passwords Don't Match");
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios.post(
        'https://elescritor-io-22.onrender.com/api/users/reset-password',
        {
          password,
          token,
        }
      );
      navigate('/Login');
      message.success(data.message);
    } catch (err) {
      message.error(getError(err));
    } finally {
      setLoading(false); // Set loading to false after the request is completed
    }
  };
  return (
    <LoginContainer className="SignupContainerMob">
      <LoginWrapper className="SignupWrapperMob">
        <div>
          <img src={LoginImage} alt="" />
        </div>
        <LoginFieldDiv className="InputFieldBlockMob">
          <h1>{t('Future.ResetPasswordHeading')}</h1>
          <form onSubmit={SubmitHandler} className="forgetforrm">
            <div>
              <label>{t('Future.Password')}</label>
              <Input.Password
                className="InputFieldMobss"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label>{t('Future.confirmPassword')}</label>
              <Input.Password
                className="InputFieldMobss"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <Loginbuttondiv>
              {loading ? (
                <Loader />
              ) : (
                <SubmitButtonForget className="logbtn" type="submit">
                  {t('Future.Submit')}
                </SubmitButtonForget>
              )}
            </Loginbuttondiv>
          </form>
        </LoginFieldDiv>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default ResetPassword;
