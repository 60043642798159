import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import PresentationLetter1 from './components/PresentationLetter/PresentationLetter1';
import InvitationLetter from './components/InvitationLetter/Invitation2';
import ResumeFriend from './components/ResumeFriendship/ResumeFriend3';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import Footer from './components/Footer/Footer';
import CartaRenuncia from './components/ProfessionalLettersPremium/CartaRenuncia/CartaRenuncia4';
import CartaSolicitando from './components/ProfessionalLettersPremium/CartaSolicitando/CartaSolicitando6';
import CartaQueja from './components/ProfessionalLettersPremium/CartaQueja/CartaQueja5';
import CartaSolicitandoPronto from './components/ProfessionalLettersPremium/CartaSolicitandoPronto/CartaSolicitandoPronto7';
import CartaAppreciation from './components/ProfessionalLettersPremium/CartaAppreciation/CartaAppreciation8';
import CartaRecommendation from './components/ProfessionalLettersPremium/CartaRecommedation/CartaRecommendation9';
import CartaAgradecimiento from './components/ProfessionalLettersPremium/CartaAgradecimiento/CartaAgradecimiento10';
import DeclaracionAmorosa from './components/PersonalLettersPremium/DeclaraciónAmorosa/DeclaracionAmorosa11';
import CartaFelicitacion from './components/PersonalLettersPremium/CartaFelicitación/CartaFelicitacion12';
import FelicitaciónporLogro from './components/PersonalLettersPremium/FelicitaciónporLogro/FelicitaciónporLogro13';
import FelicitacionCumpleaños from './components/PersonalLettersPremium/FelicitaciónCumpleaños/FelicitacionCumpleaños14';
import CartadeAnimo from './components/PersonalLettersPremium/CartadeÁnimo/CartadeAnimo15';
import CartadeInvitación from './components/PersonalLettersPremium/CartadeInvitación/CartadeInvitación16';
import CartaDisculpándote from './components/PersonalLettersPremium/CartaDisculpándote/CartaDisculpándote17';
import CartadeAmor from './components/PersonalLettersPremium/CartadeAmor/CartadeAmor18';
import CartadeDespedida from './components/PersonalLettersPremium/CartadeDespedida/CartadeDespedida19';
import YoFuturo from './components/CreativeLetters/YoFuturo/YoFuturo20';
import UniversooaDios from './components/CreativeLetters/UniversooaDios/UniversooaDios21';
import CuentodeNinos from './components/CuentodeNinos/CuentodeNinos22';
import Aboutus from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import FAQ from './components/FAQ/FAQ';
import Signup from './components/Signup/Signup';
import Login from './components/Login/Login';
import { useContext, useEffect, useRef, useState } from 'react';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import UsePolicies from './components/UsePolicies/UsePolicies';
import PaymentMethodScreen from './components/PaymentMethodScreen/PaymentMethodScreen';
import PlaceOrder from './components/PlaceOrder/PlaceOrder';
import OrderScreen from './components/OrderScreen';
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoute';
import Dashboard from './components/Dashboard';
import OrderHistory from './components/OrderHistory';
import OrderListScreen from './components/OrderListScreen';
import UserListScreen from './components/UserListScreen';
import ForgotPasswordScreen from './components/ForgotPasswordScreen';
import ResetPassword from './components/ResetPassword';
import ActivateAccount from './components/ActivateAccount';
import { Store } from './Store';
import { message } from 'antd';
import i18n from './components/i18n';
import { useCookies } from 'react-cookie';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import ThankyouMessage from './ThankyouMessage';
import NotFound from './NotFound';

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['acceptedCookies']);

  useEffect(() => {
    if (!cookies.acceptedCookies) {
      // Show your cookies popup here if needed
    }
  }, [cookies.acceptedCookies]);

  const handleAcceptCookies = () => {
    setCookie('acceptedCookies', true, {
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    });
  };

  const handleDeclineCookies = () => {
    // Close the cookies popup
    setCookie('acceptedCookies', true, {
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    });
  };
  useEffect(() => {
    const urlLang = location.pathname.split('/')[1];
    i18n.changeLanguage(urlLang);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(`/${lng}${location.pathname.slice(3)}`);
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (new Date().getTime() > localStorage.getItem('tokenExpiration')) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        message.error('Session Expired,Please Login Again');
        ctxDispatch({ type: 'USER_SIGNOUT' });
      }
    };

    checkTokenExpiration();

    const intervalId = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      // Handle ResizeObserver changes here
      console.log('ResizeObserver triggered:', entries);
    });

    observer.observe(document.body); // Observe changes in the body element

    return () => {
      observer.disconnect(); // Disconnect the observer on component unmount
    };
  }, []);
  return (
    <>
      <Navbar
        onLanguageChange={changeLanguage}
        selectedLanguage={i18n.language}
      />
      <Routes>
        <Route path="/:lang" element={<Home />} />
        <Route path="/" element={<Navigate to="/es" replace />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/:lang/Login" element={<Login />} />
        <Route path="/:lang/Signup" element={<Signup />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/:lang/About" element={<Aboutus />} />
        <Route path="/:lang/Contact" element={<Contact />} />
        <Route path="/:lang/FAQ" element={<FAQ />} />
        <Route
          path="/:lang/TermsandConditions"
          element={<TermsAndConditions />}
        />
        <Route path="/:lang/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/:lang/UsagePolicies" element={<UsePolicies />} />

        <Route
          path="/:lang/L01Presentacion"
          element={<PresentationLetter1 />}
        />
        <Route path="/:lang/L02_Invitacion" element={<InvitationLetter />} />
        <Route path="/:lang/L03_Reanudar" element={<ResumeFriend />} />
        <Route path="/:lang/L04_Renuncia" element={<CartaRenuncia />} />
        <Route path="/:lang/L05_Critica" element={<CartaQueja />} />
        <Route path="/:lang/L06_Tiempo" element={<CartaSolicitando />} />
        <Route
          path="/:lang/L07_ProntoPago"
          element={<CartaSolicitandoPronto />}
        />
        <Route path="/:lang/L08_Apreciacion" element={<CartaAppreciation />} />
        <Route
          path="/:lang/L09_Recomendacion"
          element={<CartaRecommendation />}
        />
        <Route
          path="/:lang/L10_Agradecimiento"
          element={<CartaAgradecimiento />}
        />
        <Route path="/:lang/L11_Declaracion" element={<DeclaracionAmorosa />} />
        <Route path="/:lang/L12_Nacimiento" element={<CartaFelicitacion />} />
        <Route path="/:lang/L13_Logro" element={<FelicitaciónporLogro />} />
        <Route
          path="/:lang/L14_Cumpleaños"
          element={<FelicitacionCumpleaños />}
        />
        <Route path="/:lang/L15_Animo" element={<CartadeAnimo />} />
        <Route path="/:lang/L16_Boda" element={<CartadeInvitación />} />
        <Route path="/:lang/L17_Disculpas" element={<CartaDisculpándote />} />
        <Route path="/:lang/L18_Amor" element={<CartadeAmor />} />
        <Route path="/:lang/L19_Despedida" element={<CartadeDespedida />} />
        <Route path="/:lang/L20_Futuro" element={<YoFuturo />} />
        <Route path="/:lang/L21_Universo" element={<UniversooaDios />} />
        <Route path="/:lang/L22_Cuento" element={<CuentodeNinos />} />

        <Route
          path="/:lang/SelectPaymentMethod"
          element={<PaymentMethodScreen />}
        />
        <Route path="/:lang/placeorder" element={<PlaceOrder />} />
        <Route path="/:lang/activate-account" element={<ActivateAccount />} />
        <Route path="/:lang/Thankyoumessage" element={<ThankyouMessage />} />
        <Route
          path="/:lang/admin/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/:lang/admin/orders"
          element={
            <ProtectedRoute>
              <OrderListScreen />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/:lang/admin/users"
          element={
            <ProtectedRoute>
              <UserListScreen />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/OrderHistory"
          element={
            <ProtectedRoute>
              <OrderHistory />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="/order/:id" element={<OrderScreen />}></Route>
        <Route
          path="/:lang/forget-password"
          element={<ForgotPasswordScreen />}
        ></Route>
        <Route
          path="/reset-password/:token"
          element={<ResetPassword />}
        ></Route>
      </Routes>
      <CookieConsent
        location="bottom"
        buttonText={t('Cookies.acceptcookies')}
        enableDeclineButton // Enable the decline button
        declineButtonText={t('Cookies.cancelcookies')}
        onAccept={handleAcceptCookies}
        onDecline={handleDeclineCookies}
      >
        {t('Cookies.text')}
      </CookieConsent>
      <Footer />
    </>
  );
}

export default App;
