import styled from 'styled-components';

export const PremiumContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Neutral-Silver, #f5f7fa);
  height: 45px;

  h1 {
    color: #388e3b;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  span {
    color: #000000;
    text-align: center;

    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
`;

export const PremiumBlockDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 100px;
`;

export const PremiumBlock1 = styled.div`
  display: flex;
  width: 63vw;
`;

export const PremiumBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  width: 100%;

  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4d4d;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
  }
`;

export const PremiumButton = styled.button`
  display: flex;
  width: 100%;
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Brand-Primary, #4caf4f);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 15px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
